import React from 'react';
import { Field, Form, Formik } from 'formik';
import Button from 'components/Button';

interface TradeRejectionFormValues {
  reason: 'Credit/settlement limits' | 'Fat Finger' | 'Took too long to respond' | 'Other';
  details: string;
}

interface TradeRejectionFormProps {
  onSubmit: (rejectionComment: string) => any;
}

const initialValues = {
  reason: 'Credit/settlement limits' as const,
  details: '',
};

const TradeRejectionForm: React.FC<TradeRejectionFormProps> = ({ onSubmit }) => {
  const handleSubmit = React.useCallback(
    (values: TradeRejectionFormValues) => {
      onSubmit(values.reason === 'Other' ? values.details : values.reason);
    },
    [onSubmit]
  );

  return (
    <Formik<TradeRejectionFormValues> initialValues={initialValues} onSubmit={handleSubmit}>
      {({ values }) => (
        <Form className="space-y-2.5 p-6">
          <label className="flex items-center space-x-3 cursor-pointer">
            <Field component="input" type="radio" name="reason" value="Credit/settlement limits" />
            <span>Credit/settlement limits</span>
          </label>
          <label className="flex items-center space-x-3 cursor-pointer">
            <Field component="input" type="radio" name="reason" value="Fat Finger" />
            <span>Fat Finger</span>
          </label>
          <label className="flex items-center space-x-3 cursor-pointer">
            <Field component="input" type="radio" name="reason" value="Took too long to respond" />
            <span>Took too long to respond</span>
          </label>
          <label className="flex items-center space-x-3 cursor-pointer">
            <Field component="input" type="radio" name="reason" value="Other" />
            <span>Other</span>
          </label>
          {values.reason === 'Other' && (
            <label className="flex">
              <Field
                component="textarea"
                name="details"
                rows={4}
                placeholder="Please enter reason"
                className="input input-primary-dark text-left flex-grow py-2 px-4"
              />
              <span className="sr-only">Additional Details</span>
            </label>
          )}
          <footer className="flex pt-3 justify-center">
            <Button type="submit" disabled={values.reason === 'Other' && !values.details}>
              Submit
            </Button>
          </footer>
        </Form>
      )}
    </Formik>
  );
};

export default TradeRejectionForm;
