import React from 'react';
import Routes from 'components/Routes';
import { BrowserRouter } from 'react-router-dom';
import useHiddenOutline from 'hooks/useHiddenOutline';
import { HelmetProvider } from 'react-helmet-async';
import { QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { createQueryClient } from 'client';
import SnackbarManager from 'components/utils/SnackbarManager';
import { ModalManager } from 'components/utils/ModalManager';
import Init from 'components/Init';

const queryClient = createQueryClient();

function App() {
  useHiddenOutline();

  return (
    <HelmetProvider>
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <Init>
            <Routes />
            <ModalManager />
            <SnackbarManager />
          </Init>
        </BrowserRouter>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </HelmetProvider>
  );
}

export default App;
