import React from 'react';
import { TenorSerializerListNameEnum } from 'api';
import Button from 'components/Button';
import { ReactComponent as CancelAlt } from 'assets/icons/cancel-alt.svg';
import * as Yup from 'yup';
import dayjs from 'dayjs';
import TradeCycleCountdown from './TradeCycleCountdown';
import GoodUntilDropdown from './GoodUntilDropdown';
import { CurrenciesEnum } from 'utils/constants';
import { isEqualAt } from 'utils/common';
import { Form, Formik } from 'formik';
import useAlgoRunningCheck from 'hooks/useAlgoRunningCheck';
import OrdersTable from './OrdersTable';
import useSubmitTenorOrders from './useSubmitTenorOrders';
import useModifyTenorOrders from './useModifyTenorOrders';
import { useActiveTenor, useActiveTenorsUtilities } from 'hooks/useActiveTenors';
import useFetchTenorOrders from './useFetchTenorOrders';

export type OrderFormInputValue = Record<CurrenciesEnum, number | ''>;

export interface OrdersFormValues {
  fundingCurrency: CurrenciesEnum;
  goodUntil: Date | null;
  balance: OrderFormInputValue;
  limit: OrderFormInputValue;
}

export interface TenorTradingCardProps {
  tenor: TenorSerializerListNameEnum;
}

const initialCurrenciesFieldValues = Object.values(CurrenciesEnum).reduce(
  (acc, currency) => ({ ...acc, [currency]: '' }),
  {}
) as OrderFormInputValue;

export const initialValues = {
  fundingCurrency: CurrenciesEnum.USD,
  goodUntil: null,
  balance: initialCurrenciesFieldValues,
  limit: initialCurrenciesFieldValues,
};

const validationSchema = Yup.object({
  balance: Yup.object(
    Object.values(CurrenciesEnum).reduce(
      (acc, currency) => ({ ...acc, [currency]: Yup.number() }),
      {}
    )
  ).test('at-least-one-is-defined', 'Please add at least one balance', value => {
    return Object.values(value).some(Boolean);
  }),
  limit: Yup.object(
    Object.values(CurrenciesEnum).reduce(
      (acc, currency) => ({ ...acc, [currency]: Yup.number() }),
      {}
    )
  ),
  goodUntil: Yup.mixed().test('expired', 'Your orders have expired!', value => {
    return !value || dayjs(value).isAfter(dayjs());
  }),
});

const OrdersCard: React.FC<TenorTradingCardProps> = ({ tenor }) => {
  const state = useActiveTenor(tenor);
  const { data: isAlgoRunning } = useAlgoRunningCheck();
  const { data: existingTenorOrders } = useFetchTenorOrders(tenor);
  const { setActiveTenorEditability } = useActiveTenorsUtilities();
  const modifyTenorOrders = useModifyTenorOrders(tenor);
  const submitTenorOrders = useSubmitTenorOrders(tenor);

  const isEditable = state?.isEditable ?? false;
  const firebaseValues = React.useMemo(() => {
    if (!existingTenorOrders) {
      return initialValues;
    }

    return {
      fundingCurrency: existingTenorOrders.fundingCurrency,
      goodUntil: existingTenorOrders.goodUntil ?? null,
      balance: {
        ...initialValues.balance,
        ...existingTenorOrders.details.reduce(
          (acc, order) => ({ ...acc, [order.currency]: order.balance ?? '' }),
          {}
        ),
      },
      limit: {
        ...initialValues.balance,
        ...existingTenorOrders.details.reduce(
          (acc, order) => ({ ...acc, [order.currency]: order.limit ?? '' }),
          {}
        ),
      },
    } as OrdersFormValues;
  }, [existingTenorOrders]);

  const markTenorAsEditable = React.useCallback(() => {
    setActiveTenorEditability(tenor, true);
  }, [tenor, setActiveTenorEditability]);

  // When the balances get cleared (or become `0`) before the algo matched *all* the trades, we
  // mark this tenor as editable
  React.useEffect(() => {
    if (existingTenorOrders && !existingTenorOrders.details.some(entry => Boolean(entry.balance))) {
      markTenorAsEditable();
    }
  }, [existingTenorOrders, markTenorAsEditable]);

  return (
    <section className="rounded-md shadow-lg overflow-hidden text-white">
      <Formik<OrdersFormValues>
        initialValues={isEditable ? initialValues : firebaseValues}
        onSubmit={submitTenorOrders}
        enableReinitialize={!isEditable}
        validationSchema={validationSchema}
        validateOnMount
      >
        {({ setValues, isValid, isSubmitting, values, errors, validateForm }) => {
          return (
            <Form>
              <header className="h-8 bg-darkGray-900 flex items-center">
                <h1 className="px-6 bg-darkGray-600 h-full flex items-center font-bold text-sm">
                  {tenor}
                </h1>
                <div className="px-4 h-full flex items-center space-x-2 text-xs ">
                  <h2 className="font-medium opacity-50" id={`${tenor}-next-cycle`}>
                    Next Cycle
                  </h2>
                  <div aria-describedby={`${tenor}-next-cycle`}>
                    <TradeCycleCountdown />
                  </div>
                </div>
                <div className=" h-full flex items-center space-x-2 text-xs ">
                  <h2 className="font-medium opacity-50" id={`${tenor}-good-until`}>
                    Good until
                  </h2>
                  <div aria-describedby={`${tenor}-good-until`}>
                    <GoodUntilDropdown
                      disabled={!isEditable}
                      onExpiration={() => {
                        validateForm();
                        markTenorAsEditable();
                      }}
                    />
                  </div>
                  {errors.goodUntil && <p className="text-red text-xs mx-4">{errors.goodUntil}</p>}
                </div>
              </header>
              <div className="bg-darkGray-900">
                <div className="h-2 bg-darkGray-800" />
                <OrdersTable tenor={tenor} readOnly={!isEditable} />
              </div>
              <footer className="flex items-center px-4 py-2 bg-darkGray-800">
                <Button
                  type="submit"
                  className="mr-2"
                  disabled={!isValid || isSubmitting || !isEditable}
                  loading={isSubmitting}
                >
                  Submit
                </Button>
                <Button
                  type="button"
                  variantColor="black"
                  disabled={isEditable || isAlgoRunning}
                  onClick={() => modifyTenorOrders()}
                >
                  Modify
                </Button>
                <button
                  type="button"
                  disabled={
                    isEqualAt(values, initialValues, ['goodUntil', 'balance', 'limit']) ||
                    !isEditable
                  }
                  onClick={() =>
                    setValues({
                      ...initialValues,
                      fundingCurrency: values.fundingCurrency,
                    })
                  }
                  className="ml-auto flex items-center p-1 uppercase text-darkGray-100 hover:text-white transition-colors duration-200 font-bold text-xs uppercase"
                >
                  <CancelAlt className="mr-1 fill-current text-darkGray-400" />
                  Clear
                </button>
              </footer>
            </Form>
          );
        }}
      </Formik>
    </section>
  );
};

export default React.memo(OrdersCard);
