import React from 'react';
import { Popover as ReactTinyPopover } from 'react-tiny-popover';
import useDisclosure from 'hooks/useDisclosure';
import { useTransition, animated } from 'react-spring';

type Disclosure = ReturnType<typeof useDisclosure>;

export interface PopoverProps {
  content: ((props: Disclosure) => React.ReactNode) | React.ReactNode;
  children: ((props: Disclosure) => React.ReactElement) | React.ReactElement;
}

const Popover = ({ children, content }: PopoverProps) => {
  const disclosure = useDisclosure();

  const transitions = useTransition(disclosure.isOpen, null, {
    from: { opacity: 0, transform: 'translateY(-5px)' },
    enter: { opacity: 1, transform: 'translateY(0)' },
    leave: { opacity: 0, transform: 'translateY(-5px)' },
  });

  return (
    <ReactTinyPopover
      isOpen
      positions={['bottom']}
      onClickOutside={disclosure.close}
      containerClassName="z-20"
      content={
        <React.Fragment>
          {transitions.map(
            ({ item, key, props: styles }) =>
              item && (
                <animated.div style={styles} key={key}>
                  {typeof content === 'function' ? content(disclosure) : content}
                </animated.div>
              )
          )}
        </React.Fragment>
      }
    >
      {typeof children === 'function' ? children(disclosure) : children}
    </ReactTinyPopover>
  );
};

export default React.memo(Popover);
