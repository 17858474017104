import React from 'react';
import { MODALS } from 'components/utils/ModalManager';
import { TenorSerializerListNameEnum } from 'api';
import { useModal } from 'hooks/useModal';
import useCancelTenorOrders from 'hooks/useCancelTenorOrders';
import { useActiveTenorsUtilities } from 'hooks/useActiveTenors';

const useModifyTenorOrders = (tenor: TenorSerializerListNameEnum) => {
  const { setActiveTenorEditability } = useActiveTenorsUtilities();
  const { showModal, hideModal } = useModal();
  const { mutate: cancelTenorOrders } = useCancelTenorOrders();

  return React.useCallback(() => {
    showModal({
      modal: MODALS.ORDER_CANCELLATION,
      props: {
        tenor,
        onCancel: hideModal,
        onConfirm: () => {
          try {
            setActiveTenorEditability(tenor, true);
            hideModal();
            cancelTenorOrders({ tenor });
          } catch (err) {
            setActiveTenorEditability(tenor, false);
          }
        },
      },
    });
  }, [tenor, setActiveTenorEditability]);
};

export default useModifyTenorOrders;
