import React from 'react';
import * as Yup from 'yup';
import { Field, Form, Formik } from 'formik';
import { ReactComponent as Warning } from 'assets/icons/warning.svg';
import Tooltip from '@reach/tooltip';
import Input from 'components/Input';
import Button from 'components/Button';

interface CustomTimeValues {
  hours: string;
  minutes: string;
}

interface CustomTimeFormProps {
  initialValues: CustomTimeValues;
  onSubmit: (values: CustomTimeValues) => Promise<any>;
}

const CustomTimeForm: React.FC<CustomTimeFormProps> = ({ initialValues, onSubmit }) => {
  const validationSchema = React.useMemo(() => {
    const now = new Date();
    const utcHours = now.getUTCHours();
    const utcMinutes = now.getUTCMinutes();

    return Yup.object({
      hours: Yup.number().min(utcHours).max(24).required(),
      minutes: Yup.number()
        .when('hours', {
          is: (hours: number) => hours <= utcHours,
          then: Yup.number().min(utcMinutes),
        })
        .max(60)
        .required(),
    });
  }, []);

  return (
    <Formik<CustomTimeValues>
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ isValid, dirty }) => (
        <Form>
          <fieldset className="flex items-center mb-3">
            <label htmlFor="good-until-hour" className="sr-only">
              HH
            </label>
            <Field as={Input} className="p-2 h-6 w-8" id="good-until-hour" name="hours" />
            <span className="text-sm mx-1">:</span>
            <label htmlFor="good-until-minutes" className="sr-only">
              MM
            </label>
            <Field as={Input} className="p-2 h-6 w-8" id="good-until-minutes" name="minutes" />
            <span className="opacity-50 ml-2 font-medium text-xs">GMT</span>
          </fieldset>
          <div className="flex items-center space-x-2">
            <Button type="submit" variantColor="white" disabled={!dirty || !isValid}>
              Submit
            </Button>
            {!isValid && dirty && (
              <Tooltip label="Invalid time or time in the past">
                <Warning aria-label="Form errors" />
              </Tooltip>
            )}
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default CustomTimeForm;
