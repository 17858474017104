import { useQuery } from 'react-query';
import mapKeys from 'lodash/mapKeys';
import mapValues from 'lodash/mapValues';
import { realTimeApi, TenorSerializerListNameEnum } from 'api';
import isEmpty from 'lodash/isEmpty';
import { ActiveTenors, useActiveTenorsUtilities } from 'hooks/useActiveTenors';
import { getUserDataFirebaseKey } from 'hooks/useAuth';

const useFetchTenorsWithActiveOrders = () => {
  const { setActiveTenors } = useActiveTenorsUtilities();

  const firebasePathKey = getUserDataFirebaseKey() + `orders`;
  const queryFn = () =>
    realTimeApi.fetch({ path: firebasePathKey }).then(val => {
      return mapKeys(
        mapValues(val as object, () => ({
          isEditable: false,
        })),
        (v, k) => k.replace('T_', '')
      );
    });

  return useQuery<ActiveTenors, Error>(firebasePathKey, queryFn, {
    onSuccess: tenorsWithActiveOrders => {
      setActiveTenors(
        !isEmpty(tenorsWithActiveOrders)
          ? tenorsWithActiveOrders
          : {
              [TenorSerializerListNameEnum.ON]: { isEditable: true },
              [TenorSerializerListNameEnum.TN]: { isEditable: true },
              [TenorSerializerListNameEnum._1W]: { isEditable: true },
            }
      );
    },
  });
};

export default useFetchTenorsWithActiveOrders;
