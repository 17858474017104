import React from 'react';
import Button from 'components/Button';

export interface AlgoIsRunningModalProps {
  onConfirm: () => void;
}

const AlgoIsRunningModal: React.FC<AlgoIsRunningModalProps> = ({ onConfirm }) => {
  return (
    <section className="w-96 bg-darkGray-800 py-5 p-4 rounded-md shadow-2xl">
      <p className="text-center mt-2 mb-6 text-white text-sm font-bold">
        Our algorithm is running and you are not allowed to cancel or modify your orders
      </p>
      <footer className="flex w-full justify-center">
        <Button onClick={onConfirm}>Ok</Button>
      </footer>
    </section>
  );
};

export default AlgoIsRunningModal;
