/* tslint:disable */
/* eslint-disable */
/**
 * FXSweep API
 * This is the API documentation
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface OrderSerializerDelete
 */
export interface OrderSerializerDelete {
    /**
     * 
     * @type {string}
     * @memberof OrderSerializerDelete
     */
    tenor: string;
}

export function OrderSerializerDeleteFromJSON(json: any): OrderSerializerDelete {
    return OrderSerializerDeleteFromJSONTyped(json, false);
}

export function OrderSerializerDeleteFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrderSerializerDelete {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'tenor': json['tenor'],
    };
}

export function OrderSerializerDeleteToJSON(value?: OrderSerializerDelete | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'tenor': value.tenor,
    };
}


