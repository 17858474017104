import preval from 'babel-plugin-preval/macro';

/**
	A lite version of the fully-compiled tailwind theme.
  Used/Useful for referencing colors in our javascript without going through the CSS classes.

  We are currently "picking" the properties we need since the full tailwind theme is really big and
  adds a lot in our bundle size
 */
const theme = preval`	
	const { pick } = require('lodash');
  const resolveConfig = require('tailwindcss/resolveConfig')
	const tailwindConfig = require('../tailwind.config.js');
	const { theme } = resolveConfig(tailwindConfig);

	module.exports = pick(theme, 'colors');
`;

export default theme;
