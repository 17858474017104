import React from 'react';
import { ReactComponent as Files } from 'assets/icons/files.svg';
import { Trade } from '../useFetchTrades';
import CompletedTradesTableRow from './CompletedTradesTableRow';

interface CompletedTradesTableProps {
  completedTrades: Trade[];
}

const CompletedTradesTable: React.FC<CompletedTradesTableProps> = ({ completedTrades }) => {
  const isEmpty = !completedTrades.length;
  return (
    <table
      data-testid="completed-trades-table"
      className={`max-h-full w-full font-medium border-separate border-spacing-y ${
        isEmpty ? 'h-full' : ''
      }`}
    >
      <thead className="text-xs text-white text-opacity-25">
        <tr>
          <th className="sticky top-0 bg-darkGray-800 font-medium py-3 px-2 text-left whitespace-nowrap transform translate-x-0.5">
            Ticket ID
          </th>
          <th className="sticky top-0 bg-darkGray-800 font-medium py-3 px-2 whitespace-nowrap">
            Ccy Pair
          </th>
          <th className="sticky top-0 bg-darkGray-800 font-medium py-3 px-2">Cycle</th>
          <th className="sticky top-0 bg-darkGray-800 font-medium py-3 px-2">Type</th>
          <th className="sticky top-0 bg-darkGray-800 font-medium py-3 px-2 text-left">
            Counterparty
          </th>
          <th className="sticky top-0 bg-darkGray-800 font-medium py-3 px-2">Tenor</th>
          <th className="sticky top-0 bg-darkGray-800 font-medium py-3 px-2 w-12 leading-3">
            Base Amount
          </th>
          <th className="sticky top-0 bg-darkGray-800 font-medium py-3 px-2 w-12 leading-3">
            Quoted Amount
          </th>
          <th className="sticky top-0 bg-darkGray-800 font-medium py-3 px-2 w-12 leading-3">
            Start Rate
          </th>
          <th className="sticky top-0 bg-darkGray-800 font-medium py-3 px-2">Points</th>
          <th className="sticky top-0 bg-darkGray-800 font-medium py-3 px-2 text-left">Status</th>
        </tr>
      </thead>
      <tbody>
        {!completedTrades.length && (
          <tr>
            <td colSpan={11}>
              <div className="flex justify-center items-center text-sm text-darkGray-100 space-x-3">
                <Files className="fill-current" />
                <p className="italic">There are no trades to display</p>
              </div>
            </td>
          </tr>
        )}
        {completedTrades.map(trade => (
          <CompletedTradesTableRow key={trade.id} trade={trade} />
        ))}
      </tbody>
    </table>
  );
};

export default CompletedTradesTable;
