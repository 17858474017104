/* tslint:disable */
/* eslint-disable */
/**
 * FXSweep API
 * This is the API documentation
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface LimitSerializerInput
 */
export interface LimitSerializerInput {
    /**
     * 
     * @type {number}
     * @memberof LimitSerializerInput
     */
    pairCompanyId: number;
    /**
     * 
     * @type {string}
     * @memberof LimitSerializerInput
     */
    tenor: string;
    /**
     * 
     * @type {number}
     * @memberof LimitSerializerInput
     */
    limit: number | null;
}

export function LimitSerializerInputFromJSON(json: any): LimitSerializerInput {
    return LimitSerializerInputFromJSONTyped(json, false);
}

export function LimitSerializerInputFromJSONTyped(json: any, ignoreDiscriminator: boolean): LimitSerializerInput {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'pairCompanyId': json['pair_company_id'],
        'tenor': json['tenor'],
        'limit': json['limit'],
    };
}

export function LimitSerializerInputToJSON(value?: LimitSerializerInput | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'pair_company_id': value.pairCompanyId,
        'tenor': value.tenor,
        'limit': value.limit,
    };
}


