/* tslint:disable */
/* eslint-disable */
/**
 * FXSweep API
 * This is the API documentation
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    OrderDetailSerializerInput,
    OrderDetailSerializerInputFromJSON,
    OrderDetailSerializerInputFromJSONTyped,
    OrderDetailSerializerInputToJSON,
} from './';

/**
 * 
 * @export
 * @interface OrderSerializerInput
 */
export interface OrderSerializerInput {
    /**
     * 
     * @type {string}
     * @memberof OrderSerializerInput
     */
    fundingCurrency: string;
    /**
     * 
     * @type {string}
     * @memberof OrderSerializerInput
     */
    tenor: string;
    /**
     * 
     * @type {Date}
     * @memberof OrderSerializerInput
     */
    goodUntil: Date | null;
    /**
     * 
     * @type {Array<OrderDetailSerializerInput>}
     * @memberof OrderSerializerInput
     */
    details: Array<OrderDetailSerializerInput>;
}

export function OrderSerializerInputFromJSON(json: any): OrderSerializerInput {
    return OrderSerializerInputFromJSONTyped(json, false);
}

export function OrderSerializerInputFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrderSerializerInput {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'fundingCurrency': json['funding_currency'],
        'tenor': json['tenor'],
        'goodUntil': (json['good_until'] === null ? null : new Date(json['good_until'])),
        'details': ((json['details'] as Array<any>).map(OrderDetailSerializerInputFromJSON)),
    };
}

export function OrderSerializerInputToJSON(value?: OrderSerializerInput | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'funding_currency': value.fundingCurrency,
        'tenor': value.tenor,
        'good_until': (value.goodUntil === null ? null : value.goodUntil.toISOString()),
        'details': ((value.details as Array<any>).map(OrderDetailSerializerInputToJSON)),
    };
}


