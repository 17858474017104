import React from 'react';
import Button from 'components/Button';
import { TenorSerializerListNameEnum } from 'api';

export interface OrderCancellationModalProps {
  tenor: TenorSerializerListNameEnum;
  onConfirm: () => void;
  onCancel: () => void;
}

const OrderCancellationModal: React.FC<OrderCancellationModalProps> = ({
  tenor,
  onConfirm,
  onCancel,
}) => {
  return (
    <section className="w-96 bg-darkGray-800 p-5 rounded-md shadow-2xl">
      <p className="text-center my-2 text-white text-sm font-bold">
        Are you sure you want to want to withdraw submitted orders on the <b>{tenor}</b> tenor?
      </p>
      <p className="text-center mb-6 text-white text-opacity-50 text-xs">
        <i>
          Withdrawn orders will not be entered into the next matching cycle until they have been
          submitted again.
        </i>
      </p>
      <footer className="flex w-full justify-center space-x-2">
        <Button onClick={onConfirm}>Yes</Button>
        <Button variantColor="black" onClick={onCancel}>
          No
        </Button>
      </footer>
    </section>
  );
};

export default OrderCancellationModal;
