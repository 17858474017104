import { TradeStateEnum, TradeStatusEnum } from 'utils/constants';
import { Trade } from './useFetchTrades';

export const tradeStatusToDisplayValue = (status: TradeStatusEnum) => {
  switch (status) {
    case TradeStatusEnum.ACCEPTED:
      return 'Done';
    case TradeStatusEnum.REJECTED_BY_OTHER:
      return 'They rejected';
    case TradeStatusEnum.PENDING_BY_OTHER:
      return 'Checking you';
    case TradeStatusEnum.PENDING:
    case TradeStatusEnum.PENDING_BY_YOU:
      return 'Pending';
    case TradeStatusEnum.REJECTED_BY_YOU:
      return 'You rejected';
  }
};

export const tradeStatusToNegotiationText = (status: TradeStatusEnum) => {
  switch (status) {
    case TradeStatusEnum.ACCEPTED:
      return 'This trade has been accepted by both parties';
    case TradeStatusEnum.REJECTED_BY_OTHER:
      return 'This transaction has been rejected by the counterparty';
    case TradeStatusEnum.PENDING_BY_YOU:
      return 'Amount has been updated. Accept, reject or change notional';
    case TradeStatusEnum.PENDING:
      return 'Waiting for both parties to accept, reject or change notional';
    case TradeStatusEnum.PENDING_BY_OTHER:
      return 'Waiting for counterparty to accept, reject or change notional';
    case TradeStatusEnum.REJECTED_BY_YOU:
      return 'You have rejected this transaction';
  }
};

export const checkTradeActionability = (trade: Trade) =>
  trade.status === TradeStatusEnum.PENDING ||
  trade.status === TradeStatusEnum.PENDING_BY_YOU ||
  (trade.status === TradeStatusEnum.REJECTED_BY_OTHER && trade.state === TradeStateEnum.ACTIVE);
