import React from 'react';
import Button from 'components/Button';
import { CurrenciesEnum } from 'utils/constants';

export interface FundingCurrencyConfirmationModalProps {
  fundingCurrency: CurrenciesEnum;
  onConfirm: () => void;
  onCancel: () => void;
}

const FundingCurrencyConfirmationModal: React.FC<FundingCurrencyConfirmationModalProps> = ({
  fundingCurrency,
  onConfirm,
  onCancel,
}) => {
  return (
    <section className="w-96 bg-darkGray-800 py-5 px-16 rounded-md shadow-2xl">
      <p className="text-center mt-2 mb-6 text-white text-sm font-bold">
        Would you like to change the funding currency to <b>{fundingCurrency}</b>?
      </p>
      <footer className="flex w-full justify-center space-x-2">
        <Button onClick={onConfirm}>Yes</Button>
        <Button variantColor="black" onClick={onCancel}>
          No
        </Button>
      </footer>
    </section>
  );
};

export default FundingCurrencyConfirmationModal;
