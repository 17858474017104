import React from 'react';
import { useModalState, useModal } from 'hooks/useModal';
import { animated, useTransition } from 'react-spring';
import { DialogContent, DialogOverlay } from '@reach/dialog';
import OrderCancellationModal from 'components/OrderCancellationModal';
import FundingCurrencyConfirmationModal from 'components/FundingCurrencyConfirmationModal';
import AlgoIsRunningModal from 'components/AlgoIsRunningModal';
import TradeRejectionModal from 'components/TradeRejectionModal';
import TradeCounterpartyRejectionModal from 'components/TradeCounterpartyRejectionModal';
import TenorDeactivationModal from 'components/TenorDeactivationModal';

export enum MODALS {
  ORDER_CANCELLATION = 'ORDER_CANCELLATION',
  TENOR_DEACTIVATION = 'TENOR_DEACTIVATION',
  FUNDING_CURRENCY_CONFIRMATION = 'FUNDING_CURRENCY_CONFIRMATION',
  ALGO_IS_RUNNING = 'ALGO_IS_RUNNING',
  TRADE_REJECTION = 'TRADE_REJECTION',
  TRADE_COUNTERPARTY_REJECTION = 'TRADE_COUNTERPARTY_REJECTION',
}

const AnimatedDialogOverlay = animated(DialogOverlay);
const AnimatedDialogContent = animated(DialogContent);

const ModalManager: React.FC = () => {
  const modalState = useModalState();
  const { hideModal } = useModal();

  let Component: any = null;
  switch (modalState.modal) {
    case MODALS.ORDER_CANCELLATION:
      Component = OrderCancellationModal;
      break;
    case MODALS.TENOR_DEACTIVATION:
      Component = TenorDeactivationModal;
      break;
    case MODALS.FUNDING_CURRENCY_CONFIRMATION:
      Component = FundingCurrencyConfirmationModal;
      break;
    case MODALS.ALGO_IS_RUNNING:
      Component = AlgoIsRunningModal;
      break;
    case MODALS.TRADE_REJECTION:
      Component = TradeRejectionModal;
      break;
    case MODALS.TRADE_COUNTERPARTY_REJECTION:
      Component = TradeCounterpartyRejectionModal;
      break;
  }

  const transitions = useTransition(modalState.isVisible, null, {
    from: { opacity: 0, transform: 'translateY(25px)' },
    enter: { opacity: 1, transform: 'translateY(0)' },
    leave: { opacity: 0, transform: 'translateY(25px)' },
  });

  return (
    <React.Fragment>
      {transitions.map(
        ({ item, key, props: styles }) =>
          item && (
            <AnimatedDialogOverlay
              as="div"
              isOpen
              key={key}
              onDismiss={hideModal}
              style={{ overflow: 'visible', opacity: styles.opacity }}
            >
              <AnimatedDialogContent as="div" style={styles} key={key} aria-label="Dialog">
                <Component {...modalState.props} />
              </AnimatedDialogContent>
            </AnimatedDialogOverlay>
          )
      )}
    </React.Fragment>
  );
};

export default React.memo(ModalManager);
