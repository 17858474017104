import React from 'react';
import { Formik, Form, Field, FieldArray } from 'formik';
import { TENORS } from 'utils/constants';
import { TenorSerializerListNameEnum } from 'api';
import Button from 'components/Button';
import NumberInput from 'components/NumberInput';
import groupBy from 'lodash/groupBy';
import mapValues from 'lodash/mapValues';
import sortBy from 'lodash/sortBy';
import * as Yup from 'yup';
import { ReactComponent as Info } from 'assets/icons/info.svg';
import { ReactComponent as Spinner } from 'assets/icons/spinner.svg';
import { useTenorLimits } from './useTenorLimits';
import useTenorLimitsUpdate from './useTenorLimitsUpdate';

interface TenorLimit {
  tenor: TenorSerializerListNameEnum;
  pairCompanyId: number;
  limit: number | null;
}

interface TenorLimitsFormValues {
  limits: TenorLimit[];
}

interface TenorLimitsFormProps {
  onDone: () => void;
}

const validationSchema: Yup.SchemaOf<TenorLimitsFormValues> = Yup.object({
  limits: Yup.array()
    .of(
      Yup.object({
        limit: Yup.number().min(0).nullable(),
        tenor: Yup.string().oneOf(Object.values(TenorSerializerListNameEnum)).required(),
        pairCompanyId: Yup.number().required(),
      }).required()
    )
    .required(),
});

const TenorLimitsForm: React.FC<TenorLimitsFormProps> = ({ onDone }) => {
  const { data: limits, isLoading, isError, isFetchedAfterMount } = useTenorLimits();
  const { mutateAsync } = useTenorLimitsUpdate();

  if (isLoading) {
    return (
      <div className="flex justify-center items-center p-6">
        <Spinner className="animate-spin  h-6 w-6 text-white" aria-label="Loading" />
      </div>
    );
  }

  if (isError || !limits) {
    return (
      <div className="flex justify-evenly items-center bg-darkGray-600 py-4">
        <h2 className="text-md text-red font-semibold">
          Something went wrong while loading your limits...
        </h2>
      </div>
    );
  }

  const sortedLimits = sortBy(limits, limit => limit.pairCompany.name);
  const limitsByBank = groupBy(sortedLimits, limit => limit.pairCompany.name);
  const limitsByBankSortedByTenor = mapValues(limitsByBank, tenorLimitsForBank =>
    sortBy(tenorLimitsForBank, limitPreview => TENORS.indexOf(limitPreview.tenor.name!))
  );

  const initialValues: TenorLimitsFormValues = {
    limits: Object.values(limitsByBankSortedByTenor).reduce(
      (acc, tenorLimitsForBank) => [
        ...acc,
        ...tenorLimitsForBank.map(
          limitPreview =>
            ({
              limit: limitPreview.limit ?? '',
              pairCompanyId: limitPreview.pairCompany.id,
              tenor: limitPreview.tenor.name,
            } as TenorLimit)
        ),
      ],
      [] as TenorLimit[]
    ),
  };

  const handleSubmit = async ({ limits }: TenorLimitsFormValues) => {
    try {
      const cleanedLimits = limits.map(obj => ({
        ...obj,
        // @ts-ignore - The default value of a textinput is `""` in HTML
        limit: obj.limit === '' ? null : obj.limit,
      }));

      return await mutateAsync(cleanedLimits, { onSuccess: onDone });
    } catch (err) {}
  };

  return (
    <Formik<TenorLimitsFormValues>
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      enableReinitialize={isFetchedAfterMount}
    >
      {({ isValid, isSubmitting, dirty }) => (
        <Form>
          <section className="flex flex-col items-center justify-center bg-darkGray-800 py-6">
            <div className="relative text-white ml-16">
              <div className={`grid grid-cols-${TENORS.length} gap-x-3 gap-y-1`}>
                <FieldArray name="limits">
                  {() =>
                    Object.values(limitsByBankSortedByTenor).map((tenorLimitsForBank, bankIndex) =>
                      tenorLimitsForBank.map(({ pairCompany, tenor }, tenorIndex) => {
                        const index = bankIndex * tenorLimitsForBank.length + tenorIndex;
                        return (
                          <fieldset key={`${pairCompany.name}${tenor.name}`}>
                            <label htmlFor={`limits.${index}.limit`} className="sr-only">
                              {`Limit for ${pairCompany.name} on ${tenor.name}`}
                            </label>
                            <Field
                              as={NumberInput}
                              id={`limits.${index}.limit`}
                              name={`limits.${index}.limit`}
                              className="p-2 h-6 w-12"
                              aria-describedby={`${pairCompany.name} ${tenor.name}`}
                              maxDecimals={2}
                            />
                            <input
                              name={`limits.${index}.pairCompanyId`}
                              value={pairCompany.id}
                              hidden
                              readOnly
                            />
                            <input
                              name={`limits.${index}.tenor`}
                              value={tenor.name}
                              hidden
                              readOnly
                            />
                          </fieldset>
                        );
                      })
                    )
                  }
                </FieldArray>
              </div>
              <ol className="absolute top-1 grid grid-cols-1 gap-y-2.5 transform -translate-x-20">
                {Object.keys(limitsByBankSortedByTenor).map(bankName => (
                  <li key={bankName} id={bankName} className="text-sm">
                    <b>{bankName}</b>
                  </li>
                ))}
              </ol>
              <p className="flex items-center text-gray-500 text-xs mt-5 font-medium space-x-2">
                <Info aria-label="Info" />
                <i>
                  Adjust Notional Limits. If empty – there is no limit. If the limit is set to 0 –
                  no trades are allowed.
                </i>
              </p>
              <footer className="flex space-x-4 self-start mt-5">
                <Button
                  type="submit"
                  variantColor="white"
                  loading={isSubmitting}
                  disabled={!dirty || !isValid || isSubmitting}
                >
                  Save Changes
                </Button>
                <Button variantColor="black" onClick={onDone}>
                  Cancel
                </Button>
              </footer>
            </div>
          </section>
        </Form>
      )}
    </Formik>
  );
};

export default React.memo(TenorLimitsForm);
