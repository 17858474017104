import React from 'react';
import { Trade } from 'pages/Dashboard/TradesDrawer';
import { TicketInput } from 'api';
import Button from 'components/Button';
import TradeRejectionForm from './TradeRejectionForm';

type RejectionSteps =
  | 'REJECTION_COMMENT'
  | 'TRADE_REENTRY_CONFIRMATION'
  | 'BLOCK_TRADER_CONFIRMATION';

type RejectionPayload = Pick<TicketInput, 'rejectionComment' | 'reEnterOrder' | 'blockTrader'>;

export interface TradeRejectionModalProps {
  trade: Trade;
  onComplete: (values: RejectionPayload) => Promise<void>;
}

const TradeRejectionModal: React.FC<TradeRejectionModalProps> = ({ trade, onComplete }) => {
  const [step, setStep] = React.useState<RejectionSteps>('REJECTION_COMMENT');
  const payload = React.useRef<RejectionPayload>({
    rejectionComment: '',
    blockTrader: false,
    reEnterOrder: false,
  });

  switch (step) {
    case 'REJECTION_COMMENT':
      return (
        <section className="bg-darkGray-800 rounded-md shadow-2xl text-white text-sm overflow-hidden">
          <header className="bg-garnet text-center py-2.5 px-24">
            <h1 className="font-bold">Please give the reason for rejecting this trade</h1>
          </header>
          <TradeRejectionForm
            onSubmit={comment => {
              payload.current.rejectionComment = comment;
              setStep('BLOCK_TRADER_CONFIRMATION');
            }}
          />
        </section>
      );
    case 'BLOCK_TRADER_CONFIRMATION':
      return (
        <section className="w-96 bg-darkGray-800 p-5 rounded-md shadow-2xl">
          <p className="text-center my-2 text-white text-sm font-bold">
            Would you like to stop trading "{trade.tenor}" with this Counterparty for today?
          </p>
          <footer className="flex w-full justify-center space-x-2 mt-6">
            <Button
              onClick={() => {
                payload.current.blockTrader = true;
                setStep('TRADE_REENTRY_CONFIRMATION');
              }}
            >
              Yes
            </Button>
            <Button
              variantColor="black"
              onClick={() => {
                payload.current.blockTrader = false;
                setStep('TRADE_REENTRY_CONFIRMATION');
              }}
            >
              No
            </Button>
          </footer>
        </section>
      );
    case 'TRADE_REENTRY_CONFIRMATION':
      return (
        <section className="w-96 bg-darkGray-800 p-5 rounded-md shadow-2xl">
          <p className="text-center my-2 text-white text-sm font-bold">
            Would you like to re-enter this order into the next cycle?
          </p>
          <footer className="flex w-full justify-center space-x-2 mt-6">
            <Button
              onClick={() => {
                payload.current.reEnterOrder = true;
                onComplete(payload.current);
              }}
            >
              Yes
            </Button>
            <Button
              variantColor="black"
              onClick={() => {
                payload.current.reEnterOrder = false;
                onComplete(payload.current);
              }}
            >
              No
            </Button>
          </footer>
        </section>
      );
    default:
      return null;
  }
};

export default TradeRejectionModal;
