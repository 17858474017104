import React from 'react';
import cn from 'classnames';
import { TenorSerializerListNameEnum } from 'api';

interface TenorProps {
  tenor: TenorSerializerListNameEnum;
  onClick: (tenor: TenorSerializerListNameEnum) => void;
  variant?: 'default' | 'outline';
  active?: boolean;
  disabled?: boolean;
}

const TenorControl: React.FC<TenorProps> = ({
  tenor,
  active = false,
  variant = 'default',
  onClick,
  disabled,
}) => {
  const classes = cn({
    'rounded-3xl flex items-center justify-center text-sm outline-none font-semibold transition-colors transition-opacity w-11 h-7 border border-solid': true,
    'bg-darkGray-500 border-darkGray-500 text-gray-400': variant === 'default' && !active,
    'border-white bg-white text-black': variant === 'default' && active,
    'bg-transparent border-darkGray-500 text-gray-400': variant === 'outline' && !active,
    'border-white bg-transparent text-white': variant === 'outline' && active,
  });

  return (
    <button
      id={tenor}
      aria-pressed={active}
      aria-label={`Toggle trading information for ${tenor}`}
      className={classes}
      onClick={() => onClick(tenor)}
      disabled={disabled}
    >
      {tenor}
    </button>
  );
};

export default React.memo(TenorControl);
