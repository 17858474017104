import create from 'zustand';
import { persist } from 'zustand/middleware';

type SeenCompletedTradeStoreState = {
  seenCompletedTradeIds: number[];
  setSeenCompletedTradeIds: (seenCompletedTradeIds: number[]) => void;
};

export const useSeenCompletedTradesStore = create<SeenCompletedTradeStoreState>(
  persist(
    set => ({
      seenCompletedTradeIds: [],
      setSeenCompletedTradeIds: seenCompletedTradeIds => set({ seenCompletedTradeIds }),
    }),
    {
      name: 'seen-completed-trades-storage',
    }
  )
);

export const useSeenCompletedTrades = () => useSeenCompletedTradesStore();

export const useCheckIfCompletedTradeIsSeen = (tradeId: number) =>
  useSeenCompletedTradesStore(state => state.seenCompletedTradeIds.includes(tradeId));
