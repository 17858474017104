import React from 'react';
import cn from 'classnames';
import { useField } from 'formik';

export interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  errorAfterTouched?: boolean;
}

const Input: React.FC<InputProps> = ({ className, errorAfterTouched = false, ...rest }) => {
  const [field, meta] = useField(rest.name!);

  const classes = cn({
    [className as string]: !!className,
    'input input-primary-dark': true,
    'input-error':
      !!meta.error &&
      field.value !== meta.initialValue &&
      (errorAfterTouched ? meta.touched : true),
  });

  return <input type="text" className={classes} {...rest} />;
};

export default Input;
