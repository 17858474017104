import React from 'react';
import { useTransition, animated } from 'react-spring';
import useMeasure from 'react-use-measure';
import useDisclosure from 'hooks/useDisclosure';
import { noop } from 'utils/common';
import { ReactComponent as Logo } from 'assets/icons/logo.svg';
import { ReactComponent as NL } from 'assets/icons/NL.svg';
import Backdrop from 'components/Backdrop';
import TenorControlList from './TenorControlList';
import CommonCurrencyPairs from './CommonCurrencyPairs';
import TenorLimitsForm, { usePrefetchTenorLimits } from './TenorLimitsForm';
import UserDropdown from './UserDropdown';
import { checkTradeActionability } from '../TradesDrawer/utils';
import { useFetchPendingTrades } from '../TradesDrawer';

const Header: React.FC = () => {
  usePrefetchTenorLimits();

  const { isOpen, close, toggle } = useDisclosure();
  const { data: pendingTrades } = useFetchPendingTrades();
  const [ref, { height }] = useMeasure();

  const hasActionableTrades = React.useMemo(
    () => (pendingTrades?.filter(checkTradeActionability).length ?? 0) > 0,
    [pendingTrades]
  );

  const transitions = useTransition(isOpen, null, {
    from: { height: 0 },
    enter: { height },
    leave: { height: 0 },
    update: { height },
  });

  React.useEffect(() => {
    if (hasActionableTrades) {
      close();
    }
  }, [hasActionableTrades]);

  return (
    <header className="sticky top-0 bg-darkGray-700 z-20">
      <div className="container flex items-center justify-between py-4">
        <h1>
          <Logo aria-label="FXSweep Logo" />
        </h1>
        <TenorControlList inactive={isOpen} disabled={hasActionableTrades} />
        <ul className="flex items-center space-x-6">
          <li className="inline-flex">
            <button
              aria-label="Toggle Tenor Limits"
              onClick={hasActionableTrades ? noop : toggle}
              disabled={hasActionableTrades}
            >
              <NL
                className={`fill-current stroke-current ${
                  isOpen ? 'text-white' : 'text-darkGray-100'
                }`}
              />
            </button>
          </li>
          <li className="inline-flex">
            <UserDropdown />
          </li>
        </ul>
      </div>
      {transitions.map(
        ({ item, key, props: styles }) =>
          item && (
            <animated.div
              key={key}
              style={styles}
              className="overflow-hidden fixed w-screen will-change-height"
            >
              <div ref={ref}>
                <TenorLimitsForm onDone={close} />
              </div>
            </animated.div>
          )
      )}
      <CommonCurrencyPairs />
      <Backdrop show={isOpen} className="z-10" />
    </header>
  );
};

export default React.memo(Header);
