import React from 'react';
import Button from 'components/Button';

export interface TenorDeactivationModalProps {
  onConfirm: () => void;
  onCancel: () => void;
}

const TenorDeactivationModal: React.FC<TenorDeactivationModalProps> = ({ onConfirm, onCancel }) => {
  return (
    <section className="w-96 bg-darkGray-800 p-5 rounded-md shadow-2xl">
      <p className="text-center my-2 text-white text-sm font-bold">
        Are you sure you want to close this window?
      </p>
      <p className="text-center mb-6 text-white text-opacity-50 text-xs">
        <i>All submitted orders for this tenor will be cancelled</i>
      </p>
      <footer className="flex w-full justify-center space-x-2">
        <Button onClick={onConfirm}>Yes</Button>
        <Button variantColor="black" onClick={onCancel}>
          No
        </Button>
      </footer>
    </section>
  );
};

export default TenorDeactivationModal;
