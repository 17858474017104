import create from 'zustand';
import shallow from 'zustand/shallow';
import { OrderCancellationModalProps } from 'components/OrderCancellationModal';
import { FundingCurrencyConfirmationModalProps } from 'components/FundingCurrencyConfirmationModal';
import { AlgoIsRunningModalProps } from 'components/AlgoIsRunningModal';
import { TradeRejectionModalProps } from 'components/TradeRejectionModal';
import { TradeCounterpartyRejectionModalProps } from 'components/TradeCounterpartyRejectionModal';
import { MODALS } from 'components/utils/ModalManager/ModalManager';
import { TenorDeactivationModalProps } from 'components/TenorDeactivationModal';

type ShowOrderCancellationAction = {
  modal: MODALS.ORDER_CANCELLATION;
  props: OrderCancellationModalProps;
};

type ShowTenorDeactivationAction = {
  modal: MODALS.TENOR_DEACTIVATION;
  props: TenorDeactivationModalProps;
};

type ShowFundingCurrencyConfirmationAction = {
  modal: MODALS.FUNDING_CURRENCY_CONFIRMATION;
  props: FundingCurrencyConfirmationModalProps;
};

type ShowAlgoIsRunningModal = {
  modal: MODALS.ALGO_IS_RUNNING;
  props: AlgoIsRunningModalProps;
};

type TradeRejectionModal = {
  modal: MODALS.TRADE_REJECTION;
  props: TradeRejectionModalProps;
};

type TradeCounterpartyRejectionModal = {
  modal: MODALS.TRADE_COUNTERPARTY_REJECTION;
  props: TradeCounterpartyRejectionModalProps;
};

/* The available actions that can be dispatched */
type ShowModalInput =
  | ShowOrderCancellationAction
  | ShowTenorDeactivationAction
  | ShowFundingCurrencyConfirmationAction
  | ShowAlgoIsRunningModal
  | TradeRejectionModal
  | TradeCounterpartyRejectionModal;

type ModalStoreState = {
  modal: MODALS | null;
  props: Record<string, any>;
  isVisible: boolean;
  showModal: (input: ShowModalInput) => void;
  hideModal: () => void;
};

export const useModalStore = create<ModalStoreState>(set => ({
  modal: null,
  props: {},
  isVisible: false,
  showModal: ({ modal, props }) =>
    set({
      modal,
      props: props ?? {},
      isVisible: true,
    }),
  hideModal: () => set({ isVisible: false }),
}));

export const useModalState = () =>
  useModalStore(
    state => ({
      modal: state.modal,
      props: state.props,
      isVisible: state.isVisible,
    }),
    shallow
  );

export const useModal = () =>
  useModalStore(
    state => ({
      showModal: state.showModal,
      hideModal: state.hideModal,
    }),
    shallow
  );
