import cn from 'classnames';
import { CurrenciesEnum } from 'utils/constants';
import reduce from 'lodash/reduce';
import { TenorCurrencyMarketData } from './useTenorCurrencyMarketData';
import findKey from 'lodash/findKey';
import { OrderFormInputValue } from '../OrdersCard';

export const getInputClasses = (value: string | number, disabled: boolean) =>
  cn({
    'w-14 h-6 transition-opacity duration-100 focus:placeholder-opacity-0': true,
    'opacity-0 hover:opacity-100 focus:opacity-100': value === '',
    'pointer-events-none': disabled,
  });

export const getRowClasses = (active: boolean) =>
  cn({
    'transition-background duration-100 hover:bg-darkGray-800 focus-within:bg-darkGray-800 text-white': true,
    'text-opacity-15': !active,
    'bg-darkGray-800 text-opacity-70': active,
  });

export const getCurrencyButtonClasses = (active: boolean) =>
  cn({
    'font-bold p-1.5 hover:text-yellow transition-colors': true,
    'text-yellow funding-currency-ribbon': active,
  });

export const getFundingCurrencyClasses = (value: number | null) =>
  cn({
    'font-medium': true,
    'text-green': value !== null && value > 0,
    'text-red': value !== null && value < 0,
  });

export const getFundingCurrencyNegativeSum = (
  fundingCurrency: CurrenciesEnum,
  balances: OrderFormInputValue,
  usdMarketData: TenorCurrencyMarketData
) => {
  const sum = reduce(
    balances,
    (acc, balance, currency) => {
      if (!balance) {
        return acc;
      }

      if (currency === CurrenciesEnum.USD) {
        return acc + balance;
      }

      const currencyPairWithUSD = findKey(usdMarketData, (data, pair) => pair.includes(currency))!;
      const currencySpotAgainstUSD = usdMarketData[currencyPairWithUSD].spot;
      return (
        acc +
        (currencyPairWithUSD.startsWith(CurrenciesEnum.USD)
          ? balance / currencySpotAgainstUSD
          : balance * currencySpotAgainstUSD)
      );
    },
    0
  );

  if (fundingCurrency === CurrenciesEnum.USD) {
    return -1 * sum;
  }

  const fundingCurrencyPairWithUSD = findKey(usdMarketData, (data, pair) =>
    pair.includes(fundingCurrency)
  )!;
  const fundingCurrencySpotAgainstUSD = usdMarketData[fundingCurrencyPairWithUSD].spot;

  return (
    -1 *
    (fundingCurrencyPairWithUSD.startsWith(CurrenciesEnum.USD)
      ? sum * fundingCurrencySpotAgainstUSD
      : sum / fundingCurrencySpotAgainstUSD)
  );
};
