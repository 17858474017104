import React from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { limitsApi } from 'api';

// Polyfilling requestIdleCallback because Safari is not supporting it
window.requestIdleCallback = window.requestIdleCallback || ((cb: Function) => cb());

export const queryKey = 'tenor-limits';
export const queryFn = () => limitsApi.limitsList();

export const useTenorLimits = () => useQuery(queryKey, queryFn, { refetchOnMount: 'always' });

export const usePrefetchTenorLimits = () => {
  const client = useQueryClient();

  React.useEffect(() => {
    window.requestIdleCallback(() => {
      client.prefetchQuery(queryKey, queryFn);
    });
  }, []);
};

export default useTenorLimits;
