import React from 'react';
import { TenorSerializerListNameEnum } from 'api';
import { useActiveTenorsUtilities } from 'hooks/useActiveTenors';

const useTenorActivation = () => {
  const { addActiveTenor } = useActiveTenorsUtilities();

  return React.useCallback(
    (tenor: TenorSerializerListNameEnum) => {
      addActiveTenor(tenor);
    },
    [addActiveTenor]
  );
};

export default useTenorActivation;
