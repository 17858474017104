import React from 'react';
import dayjs from 'dayjs';
import utcPlugin from 'dayjs/plugin/utc';
import isDeepEqual from 'fast-deep-equal';
import theme from 'theme';
import { getRateDecimalsForCurrencyPair, isTest } from 'utils/common';
import { ReactComponent as CheckAlt } from 'assets/icons/check-alt.svg';
import { ReactComponent as CancelAlt } from 'assets/icons/cancel-alt.svg';
import { TradeStatusEnum } from 'utils/constants';
import { Trade } from '../../useFetchTrades';
import { useCheckIfCompletedTradeIsSeen } from '../../useSeenCompletedTrades';
import { tradeStatusToDisplayValue } from '../../utils';

dayjs.extend(utcPlugin);

interface CompletedTradesTableRowProps {
  trade: Trade;
}

const CompletedTradesTableRow: React.FC<CompletedTradesTableRowProps> = ({ trade }) => {
  const isTradeSeen = useCheckIfCompletedTradeIsSeen(trade.id);
  const rowRef = React.useRef<HTMLTableRowElement>(null);
  const firstCellRef = React.useRef<HTMLTableCellElement>(null);

  const isTradeAccepted = trade.status === TradeStatusEnum.ACCEPTED;
  React.useEffect(() => {
    if (!isTradeSeen && !isTest()) {
      const backgroundColor = 'rgba(255,255,255,0.05)';
      rowRef.current?.animate(
        [
          { backgroundColor: 'transparent' },
          { backgroundColor, offset: 0.2 },
          { backgroundColor, offset: 0.8 },
          { backgroundColor: 'transparent' },
        ],
        { duration: 3000, iterations: 1 }
      );

      const borderColor = isTradeAccepted ? theme.colors.green : theme.colors.garnet;
      firstCellRef.current?.animate(
        [
          { borderColor: 'transparent' },
          { borderColor, offset: 0.2 },
          { borderColor, offset: 0.8 },
          { borderColor: 'transparent' },
        ],
        { duration: 3000, iterations: 1 }
      );
    }
  }, [isTradeSeen]);

  return (
    <tr ref={rowRef} className="text-white text-opacity-50 text-sm w-full">
      <td
        ref={firstCellRef}
        className="font-bold pl-3 p-1 rounded-l-sm text-white text-opacity-100 border-l-2 border-transparent"
      >
        {trade.id}
      </td>
      <td className="text-center p-1">{trade.ccyPair}</td>
      <td className="text-center p-1 whitespace-nowrap">
        {dayjs.utc(trade.cycle).format('MM.YY HH:mm')}
      </td>
      <td className="text-center p-1">
        {trade.company === trade.pairCompany ? 'Internal' : 'External'}
      </td>
      <td className="p-1 pl-2 whitespace-nowrap">{trade.pairCompany}</td>
      <td className="text-center text-white text-opacity-75 p-1">{trade.tenor}</td>
      <td className={`text-center p-1 ${trade.baseAmount < 0 ? 'text-red' : 'text-green'}`}>
        {trade.baseAmount.toFixed(2)}
      </td>
      <td className={`text-center p-1 ${trade.quotedAmount < 0 ? 'text-red' : 'text-green'}`}>
        {trade.quotedAmount.toFixed(2)}
      </td>
      <td className="text-center p-1">
        {trade.startRate.toFixed(getRateDecimalsForCurrencyPair(trade.ccyPair))}
      </td>
      <td className="text-center p-1 text-white text-opacity-75">{trade.fwdPoints.toFixed(2)}</td>
      <td className="p-1 pl-2 space-x-2 whitespace-nowrap">
        {isTradeAccepted ? (
          <CheckAlt className="inline-block fill-current text-green" />
        ) : (
          <CancelAlt className="inline-block fill-current text-garnet" />
        )}
        <span className="text-white text-opacity-75">
          {tradeStatusToDisplayValue(trade.status)}
        </span>
      </td>
    </tr>
  );
};

export default React.memo(CompletedTradesTableRow, isDeepEqual);
