/* tslint:disable */
/* eslint-disable */
/**
 * FXSweep API
 * This is the API documentation
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AuthenticationResponse,
    AuthenticationResponseFromJSON,
    AuthenticationResponseToJSON,
    MyTokenObtainPair,
    MyTokenObtainPairFromJSON,
    MyTokenObtainPairToJSON,
    TokenRefresh,
    TokenRefreshFromJSON,
    TokenRefreshToJSON,
} from '../models';

export interface TokenCreateRequest {
    data: MyTokenObtainPair;
}

export interface TokenRefreshCreateRequest {
    data: TokenRefresh;
}

/**
 * 
 */
export class TokenApi extends runtime.BaseAPI {

    /**
     */
    async tokenCreateRaw(requestParameters: TokenCreateRequest): Promise<runtime.ApiResponse<AuthenticationResponse>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling tokenCreate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // api_key authentication
        }

        const response = await this.request({
            path: `/token/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MyTokenObtainPairToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => AuthenticationResponseFromJSON(jsonValue));
    }

    /**
     */
    async tokenCreate(requestParameters: TokenCreateRequest): Promise<AuthenticationResponse> {
        const response = await this.tokenCreateRaw(requestParameters);
        return await response.value();
    }

    /**
     * Takes a refresh type JSON web token and returns an access type JSON web token if the refresh token is valid.
     */
    async tokenRefreshCreateRaw(requestParameters: TokenRefreshCreateRequest): Promise<runtime.ApiResponse<AuthenticationResponse>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling tokenRefreshCreate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // api_key authentication
        }

        const response = await this.request({
            path: `/token/refresh/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TokenRefreshToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => AuthenticationResponseFromJSON(jsonValue));
    }

    /**
     * Takes a refresh type JSON web token and returns an access type JSON web token if the refresh token is valid.
     */
    async tokenRefreshCreate(requestParameters: TokenRefreshCreateRequest): Promise<AuthenticationResponse> {
        const response = await this.tokenRefreshCreateRaw(requestParameters);
        return await response.value();
    }

}
