/*
<svg class="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
          <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
          <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
        </svg>
 */

import React from 'react';
import cn from 'classnames';
import { ReactComponent as Spinner } from 'assets/icons/spinner.svg';

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  loading?: boolean;
  variant?: 'primary';
  variantColor?: 'white' | 'black' | 'green' | 'red';
}

const Button: React.FC<ButtonProps> = ({
  loading = false,
  variant = 'primary',
  variantColor = 'white',
  className = '',
  children,
  ...rest
}) => {
  const buttonClasses = cn({
    [className]: !!className,
    'btn inline-flex justify-center items-center': true,
    'btn-primary-light': variant === 'primary' && variantColor === 'white',
    'btn-primary-dark': variant === 'primary' && variantColor === 'black',
    'btn-primary-success': variant === 'primary' && variantColor === 'green',
    'btn-primary-error': variant === 'primary' && variantColor === 'red',
  });

  const svgClasses = cn({
    'animate-spin -ml-1 mr-2 h-4 w-4': true,
    'text-white': variantColor === 'black',
    'text-black': variantColor === 'white',
  });

  return (
    <button type="button" className={buttonClasses} {...rest}>
      {loading && <Spinner className={svgClasses} aria-label="Spinner" />}
      {children}
    </button>
  );
};

export default Button;
