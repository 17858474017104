import { User } from 'clients/Auth';
import create from 'zustand';
import shallow from 'zustand/shallow';

type AuthStoreState = {
  isAuthenticated: boolean | undefined;
  authUser: User | null;
  setAuthState: (state: Partial<AuthStoreState>) => void;
};

export const useAuthStore = create<AuthStoreState>(set => ({
  isAuthenticated: undefined,
  authUser: null,
  setAuthState: state => set(state),
}));

export const useAuth = () =>
  useAuthStore(
    state => ({ isAuthenticated: state.isAuthenticated, authUser: state.authUser }),
    shallow
  );

export const useAuthUtilities = () =>
  useAuthStore(state => ({ setAuthState: state.setAuthState }), shallow);

export const getUserDataFirebaseKey = () => {
  const userId = useAuthStore.getState().authUser?.userId;
  return `user_data/F${userId}/`;
};
