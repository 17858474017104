import React from 'react';
import { ReactComponent as Chevron } from 'assets/icons/chevron.svg';
import { ReactComponent as Warning } from 'assets/icons/warning.svg';
import Backdrop from 'components/Backdrop';
import { isTest, noop } from 'utils/common';
import difference from 'lodash/difference';
import { animated, useTransition } from 'react-spring';
import useDisclosure from 'hooks/useDisclosure';
import { checkTradeActionability } from './utils';
import PendingTradesTable from './PendingTradesTable';
import CompletedTradesTable from './CompletedTradesTable';
import useFetchPendingTrades from './useFetchPendingTrades';
import useFetchCompletedTrades from './useFetchCompletedTrades';
import { useSeenCompletedTrades } from './useSeenCompletedTrades';

const DRAWER_WIDTH = 806;

const TradesDrawer: React.FC = () => {
  const pendingTradesRef = React.useRef<HTMLDivElement>(null);
  const { isOpen, open, toggle } = useDisclosure();
  const { seenCompletedTradeIds, setSeenCompletedTradeIds } = useSeenCompletedTrades();
  const { data: pendingTrades, isLoading: isLoadingPendingTrades } = useFetchPendingTrades();
  const { data: completedTrades, isLoading: isLoadingCompletedTrades } = useFetchCompletedTrades();

  const actionableTradesCount = React.useMemo(
    () => pendingTrades?.filter(checkTradeActionability).length ?? 0,
    [pendingTrades]
  );

  const unseenCompletedTradesCount = React.useMemo(
    () => difference(completedTrades?.map(t => t.id) ?? [], seenCompletedTradeIds).length,
    [isOpen, completedTrades, seenCompletedTradeIds]
  );

  const hasActionableTrades = actionableTradesCount > 0;
  const hasUnseenCompletedTrades = unseenCompletedTradesCount > 0;

  React.useEffect(() => {
    if (isOpen && completedTrades) {
      setSeenCompletedTradeIds(completedTrades.map(t => t.id));
    }
  }, [isOpen, completedTrades]);

  React.useEffect(() => {
    if (hasActionableTrades) {
      open();

      if (!isTest()) {
        pendingTradesRef.current?.animate(
          [
            { backgroundColor: 'initial' },
            { backgroundColor: '#9A0F31' },
            { backgroundColor: 'initial' },
          ],
          { duration: 1750, iterations: 3 }
        );
      }
    }
  }, [hasActionableTrades]);

  const transitions = useTransition(isOpen, null, {
    from: { width: 0 },
    enter: { width: DRAWER_WIDTH },
    leave: { width: 0 },
  });

  return (
    <aside className="flex h-full border-t border-darkGray-900 overflow-x-hidden">
      <button
        aria-label="Toggle Trades Drawer"
        onClick={hasActionableTrades ? noop : toggle}
        className="flex items-stretch relative"
      >
        <Chevron
          className={`absolute inset-x-0 top-6 m-auto text-white fill-current opacity-20 ${
            isOpen ? 'transform rotate-180' : ''
          }`}
        />
        <div className="w-12 bg-darkGray-700 divide-y divide-white divide-opacity-10">
          <div ref={pendingTradesRef} className="flex justify-center items-center h-3/6">
            <h2 className="transform -rotate-90 whitespace-nowrap font-bold text-white flex items-center space-x-3">
              <span className="uppercase tracking-widest">Pending Trades</span>
              {hasActionableTrades && (
                <span className="rounded-full bg-red w-5 h-5 flex justify-center items-center font-bold text-sm">
                  {actionableTradesCount}
                </span>
              )}
            </h2>
          </div>
          <div className="flex justify-center items-center h-3/6">
            <h2 className="transform -rotate-90 whitespace-nowrap font-bold text-white flex items-center space-x-3">
              <span className="uppercase tracking-widest">Blotter</span>
              {hasUnseenCompletedTrades && (
                <span className="rounded-full bg-red w-5 h-5 flex justify-center items-center font-bold text-sm">
                  {unseenCompletedTradesCount}
                </span>
              )}
            </h2>
          </div>
        </div>
        <Chevron
          className={`absolute inset-x-0 bottom-6 m-auto text-white fill-current opacity-20 ${
            isOpen ? 'transform rotate-180' : ''
          }`}
        />
      </button>
      {transitions.map(
        ({ item, key, props: styles }) =>
          item && (
            <animated.div key={key} style={styles}>
              <div
                data-testid="trades-drawer"
                className="bg-darkGray-800 h-full divide-y divide-white divide-opacity-10 min-w-min"
              >
                <section className="h-3/6 p-6 flex flex-col">
                  <header>
                    <h1 className="px-2 text-xl font-medium text-white">Pending Trades</h1>
                  </header>
                  <div className="flex-grow overflow-y-auto">
                    {!isLoadingPendingTrades && (
                      <PendingTradesTable pendingTrades={pendingTrades!} />
                    )}
                  </div>
                </section>
                <section className="h-3/6 p-6 flex flex-col">
                  <header>
                    <h1 className="px-2 text-xl font-medium text-white">Blotter</h1>
                  </header>
                  <div className="flex-grow overflow-y-auto">
                    {!isLoadingCompletedTrades && (
                      <CompletedTradesTable completedTrades={completedTrades!} />
                    )}
                  </div>
                </section>
              </div>
            </animated.div>
          )
      )}
      <Backdrop show={isOpen}>
        {hasActionableTrades && (
          <div className="flex justify-center items-center w-full h-full">
            <div
              className="flex flex-col items-center space-y-4"
              style={{ transform: `translateX(-${DRAWER_WIDTH / 2}px)` }}
            >
              <Warning width={35} height={30} aria-label="Screen is locked" />
              <p className="w-48 text-center text-md font-medium italic text-white text-opacity-50">
                You have pending trades to accept
              </p>
            </div>
          </div>
        )}
      </Backdrop>
    </aside>
  );
};

export default React.memo(TradesDrawer);
