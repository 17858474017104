import useRealTimeQuery from 'hooks/useRealTimeQuery';

export const getFirebasePathKey = () => 'market_data/common';

export type CommonCurrencyPairMarketData = {
  [currencyPair: string]: {
    spot: number;
  };
};

const useCommonCurrencyPairMarketData = () =>
  useRealTimeQuery<CommonCurrencyPairMarketData>(getFirebasePathKey());

export default useCommonCurrencyPairMarketData;
