import create from 'zustand';
import shallow from 'zustand/shallow';
import { SnackbarProps } from 'components/Snackbar';

export type SnackbarPublicProps = Omit<SnackbarProps, 'destroy'>;
export type SnackbarItemState = SnackbarPublicProps & { id: string };
export type SnackbarContextValue = {
  snackbars: SnackbarItemState[];
  pushSnackbar: (props: SnackbarPublicProps) => void;
  removeSnackbar: (id: string) => void;
};

const generateSnackbarId = () => Math.random().toString(36).substr(2, 5);

export const useSnackbarStore = create<SnackbarContextValue>(set => ({
  snackbars: [],
  pushSnackbar: (props: SnackbarPublicProps) =>
    set(({ snackbars }) => ({
      snackbars: [...snackbars, { id: generateSnackbarId(), ...props }],
    })),
  removeSnackbar: (id: string) =>
    set(({ snackbars }) => ({ snackbars: snackbars.filter(s => s.id !== id) })),
}));

export const useSnackbarState = () => useSnackbarStore(state => state.snackbars);

export const useSnackbar = () =>
  useSnackbarStore(
    state => ({ pushSnackbar: state.pushSnackbar, removeSnackbar: state.removeSnackbar }),
    shallow
  );
