/* tslint:disable */
/* eslint-disable */
/**
 * FXSweep API
 * This is the API documentation
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    LimitPreview,
    LimitPreviewFromJSON,
    LimitPreviewToJSON,
    LimitSerializerInputSwagger,
    LimitSerializerInputSwaggerFromJSON,
    LimitSerializerInputSwaggerToJSON,
} from '../models';

export interface LimitsCreateRequest {
    data: LimitSerializerInputSwagger;
}

/**
 * 
 */
export class LimitsApi extends runtime.BaseAPI {

    /**
     */
    async limitsCreateRaw(requestParameters: LimitsCreateRequest): Promise<runtime.ApiResponse<Array<LimitPreview>>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling limitsCreate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // api_key authentication
        }

        const response = await this.request({
            path: `/limits/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: LimitSerializerInputSwaggerToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(LimitPreviewFromJSON));
    }

    /**
     */
    async limitsCreate(requestParameters: LimitsCreateRequest): Promise<Array<LimitPreview>> {
        const response = await this.limitsCreateRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async limitsListRaw(): Promise<runtime.ApiResponse<Array<LimitPreview>>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // api_key authentication
        }

        const response = await this.request({
            path: `/limits/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(LimitPreviewFromJSON));
    }

    /**
     */
    async limitsList(): Promise<Array<LimitPreview>> {
        const response = await this.limitsListRaw();
        return await response.value();
    }

}
