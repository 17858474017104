import React from 'react';
import { ReactComponent as Inbox } from 'assets/icons/inbox.svg';
import { Trade } from '../useFetchTrades';
import PendingTradesTableRow from './PendingTradesTableRow';

interface PendingTradesTableProps {
  pendingTrades: Trade[];
}

const PendingTradesTable: React.FC<PendingTradesTableProps> = ({ pendingTrades }) => {
  const [activeId, setActiveId] = React.useState<number | null>(null);

  const handleRowClick = React.useCallback(
    (id: number) => {
      setActiveId(prev => (prev === id ? null : id));
    },
    [setActiveId]
  );

  const isEmpty = !pendingTrades.length;
  return (
    <table
      data-testid="pending-trades-table"
      className={`max-h-full w-full font-medium border-separate border-spacing-y ${
        isEmpty ? 'h-full' : ''
      }`}
    >
      <thead className="text-xs text-white text-opacity-25">
        <tr>
          <th className="sticky top-0 bg-darkGray-800 font-medium py-3 px-2 text-left whitespace-nowrap">
            Ticket ID
          </th>
          <th className="sticky top-0 bg-darkGray-800 font-medium py-3 px-2 whitespace-nowrap">
            Ccy Pair
          </th>
          <th className="sticky top-0 bg-darkGray-800 font-medium py-3 px-2">Cycle</th>
          <th className="sticky top-0 bg-darkGray-800 font-medium py-3 px-2">Type</th>
          <th className="sticky top-0 bg-darkGray-800 font-medium py-3 px-2 text-left">
            Counterparty
          </th>
          <th className="sticky top-0 bg-darkGray-800 font-medium py-3 px-2">Tenor</th>
          <th className="sticky top-0 bg-darkGray-800 font-medium py-3 px-2 w-12 leading-3">
            Base Amount
          </th>
          <th className="sticky top-0 bg-darkGray-800 font-medium py-3 px-2 w-12 leading-3">
            Quoted Amount
          </th>
          <th className="sticky top-0 bg-darkGray-800 font-medium py-3 px-2 w-12 leading-3">
            Start Rate
          </th>
          <th className="sticky top-0 bg-darkGray-800 font-medium py-3 px-2">Points</th>
          <th className="sticky top-0 bg-darkGray-800 font-medium py-3 px-2 text-left">Status</th>
        </tr>
      </thead>
      <tbody>
        {!pendingTrades.length && (
          <tr>
            <td colSpan={11}>
              <div className="flex justify-center items-center text-sm text-darkGray-100 space-x-3">
                <Inbox className="fill-current" />
                <p className="italic">There are no Pending Trades at the moment</p>
              </div>
            </td>
          </tr>
        )}
        {pendingTrades.map(trade => (
          <PendingTradesTableRow
            key={trade.id}
            trade={trade}
            isActive={trade.id === activeId}
            toggleActive={handleRowClick}
          />
        ))}
      </tbody>
    </table>
  );
};

export default PendingTradesTable;
