import React from 'react';
import Popover from 'components/Popover';
import { ReactComponent as Caret } from 'assets/icons/caret.svg';
import CustomTimeForm from './CustomTimeForm';
import { minTwoDigits } from 'utils/common';
import { useFormikContext } from 'formik';
import dayjs from 'dayjs';
import utcPlugin from 'dayjs/plugin/utc';
import { OrdersFormValues } from '../OrdersCard';

dayjs.extend(utcPlugin);

const createUTCTime = (hours: number, minutes: number) => {
  const now = Date.now();
  return dayjs.utc(now).hour(hours).minute(minutes).toDate();
};

interface GoodUntilDropdownProps {
  disabled?: boolean;
  onExpiration?: () => void;
}

const GoodUntilDropdown: React.FC<GoodUntilDropdownProps> = ({
  onExpiration,
  disabled = false,
}) => {
  const {
    values: { goodUntil },
    setFieldValue,
  } = useFormikContext<OrdersFormValues>();

  const utcHours = goodUntil ? minTwoDigits(goodUntil.getUTCHours()) : '';
  const utcMinutes = goodUntil ? minTwoDigits(goodUntil.getUTCMinutes()) : '';
  const onChange = (value: Date | null) => setFieldValue('goodUntil', value);

  React.useEffect(() => {
    if (goodUntil !== null && onExpiration) {
      const msTillExpiration = goodUntil.getTime() - Date.now();
      if (msTillExpiration > 0) {
        const timeoutId = setTimeout(() => onExpiration(), msTillExpiration);
        return () => clearTimeout(timeoutId);
      }
    }
  }, [goodUntil, onExpiration]);

  return (
    <Popover
      content={({ close }) => (
        <div
          className="bg-darkGray-600 text-white rounded mt-1.5 ml-24 shadow-2xl"
          data-testid="good-until-popover"
        >
          <div className="px-2 py-1.5">
            <button
              className="flex items-center space-x-3 p-2 hover:bg-darkGray-800 rounded w-full font-bold text-xs-sm"
              onClick={() => {
                onChange(null);
                close();
              }}
            >
              Good Till Cancelled
            </button>
          </div>
          <div className="w-full bg-white opacity-5 h-0.5" />
          <div className="p-4 font-medium text-xs">
            <p className="opacity-50 mb-2 -mt-1">Enter custom time</p>
            <CustomTimeForm
              initialValues={{ hours: utcHours, minutes: utcMinutes }}
              onSubmit={async values => {
                onChange(createUTCTime(Number(values.hours), Number(values.minutes)));
                close();
              }}
            />
          </div>
        </div>
      )}
    >
      {({ toggle }) => (
        <button
          type="button"
          aria-label="Change good until"
          className="bg-black flex items-center text-xs-sm font-bold rounded-full pl-2 pr-1 py-0.5 space-x-1"
          onClick={toggle}
          disabled={disabled}
        >
          <span>{goodUntil ? `${utcHours}:${utcMinutes}` : 'GTC'}</span>
          <Caret className="fill-current opacity-25" />
        </button>
      )}
    </Popover>
  );
};

export default GoodUntilDropdown;
