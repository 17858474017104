import { useMutation } from 'react-query';
import { ordersApi, OrderSerializerDelete } from 'api';
import { useSnackbar } from 'hooks/useSnackbar';

const mutationFn = (data: OrderSerializerDelete) => ordersApi.ordersDelete({ data });

export const useCancelTenorOrders = () => {
  const { pushSnackbar } = useSnackbar();

  return useMutation(mutationFn, {
    onError: () => {
      pushSnackbar({ variant: 'error', message: 'Failed to cancel your existing orders' });
    },
  });
};

export default useCancelTenorOrders;
