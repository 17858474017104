import React from 'react';
import useCommonCurrencyPairMarketData from './useCommonCurrencyPairMarketData';
import map from 'lodash/map';
import { getRateDecimalsForCurrencyPair } from 'utils/common';

const CommonCurrencyPairs: React.FC = () => {
  const { data, isLoading, error } = useCommonCurrencyPairMarketData();

  if (isLoading) {
    return null;
  }

  if (error) {
    return (
      <div className="flex justify-evenly items-center bg-darkGray-800 py-4">
        <span className="text-sm text-red font-semibold">{error.message}</span>
      </div>
    );
  }

  return (
    <div className="bg-darkGray-800 animate-fade-in">
      <dl className="container flex justify-evenly items-center py-4">
        {map(data, ({ spot }, currencyPair) => (
          <span key={currencyPair} className="flex space-x-2">
            <dt className="text-gray-300 text-sm font-bold">{currencyPair}</dt>
            <dd className="text-gray-400 text-sm font-semibold">
              {spot.toFixed(getRateDecimalsForCurrencyPair(currencyPair))}
            </dd>
          </span>
        ))}
      </dl>
    </div>
  );
};

export default React.memo(CommonCurrencyPairs);
