import { useMutation, useQueryClient } from 'react-query';
import { ticketApi, TicketInput, TicketInputActionEnum, TicketReturn } from 'api';
import { useSnackbar } from 'hooks/useSnackbar';
import { TradeStateEnum, TradeStatusEnum } from 'utils/constants';
import { queryKey as tenorLimitsQueryKey } from 'pages/Dashboard/Header/TenorLimitsForm';
import { usePendingTradesFirebasePathKey } from '../../../useFetchPendingTrades';
import { useCompletedTradesFirebasePathKey } from '../../../useFetchCompletedTrades';
import { Trade } from '../../../useFetchTrades';

interface MutationContext {
  previousPendingTrades: Trade[];
  previousCompletedTrades: Trade[];
}

const mutationFn = (data: TicketInput) => ticketApi.ticketCreate({ data });

export const useUpdateTrade = () => {
  const queryClient = useQueryClient();
  const { pushSnackbar } = useSnackbar();

  const pendingTradesKey = usePendingTradesFirebasePathKey();
  const completedTradesKey = useCompletedTradesFirebasePathKey();

  return useMutation<TicketReturn, Error, TicketInput, MutationContext>(mutationFn, {
    onMutate: ({ id, action }) => {
      const previousPendingTrades = queryClient.getQueryData<Trade[]>(pendingTradesKey)!;
      const previousCompletedTrades = queryClient.getQueryData<Trade[]>(completedTradesKey)!;

      const trade = previousPendingTrades!.find(t => t.id === id)!;
      const optimisticallyUpdatedTrade = {
        ...trade,
        ...(action === TicketInputActionEnum.ACCEPT && {
          status: TradeStatusEnum.PENDING_BY_OTHER,
        }),
        ...(action === TicketInputActionEnum.REJECT && {
          state: TradeStateEnum.FINALIZED,
          status:
            trade.status === TradeStatusEnum.REJECTED_BY_OTHER
              ? trade.status
              : TradeStatusEnum.REJECTED_BY_YOU,
        }),
      };

      // If user rejected, we remove trade from pending trades and put it in blotter
      if (action === TicketInputActionEnum.REJECT) {
        queryClient.setQueryData<Trade[]>(pendingTradesKey, existing =>
          existing!.filter(t => t !== trade)
        );

        queryClient.setQueryData<Trade[]>(completedTradesKey, existing => [
          optimisticallyUpdatedTrade,
          ...(existing ?? []),
        ]);
      }

      // If user accepted, we can't yet move to blotter (we are waiting for counterparty), so we
      // simply optimistically update the status
      if (action === TicketInputActionEnum.ACCEPT) {
        queryClient.setQueryData<Trade[]>(pendingTradesKey, existing =>
          existing!.map(t => (t === trade ? optimisticallyUpdatedTrade : t))
        );
      }

      return { previousPendingTrades, previousCompletedTrades };
    },
    onSuccess: (data, variables) => {
      if (variables.action === TicketInputActionEnum.ACCEPT) {
        pushSnackbar({ variant: 'default', message: 'Successfully updated trade' });
      } else {
        pushSnackbar({ variant: 'default', message: 'Trade has been rejected' });
      }

      if (variables.blockTrader) {
        queryClient.refetchQueries(tenorLimitsQueryKey, { exact: true });
      }
    },
    onError: (error: Error, variables, context) => {
      pushSnackbar({ variant: 'error', message: `Request failed. ${error.message}` });

      // Restore optimistic updates to original values
      queryClient.setQueryData(pendingTradesKey, context?.previousPendingTrades)!;
      queryClient.setQueryData(completedTradesKey, context?.previousCompletedTrades);
    },
  });
};

export default useUpdateTrade;
