import React from 'react';
import { useActiveTenors } from 'hooks/useActiveTenors';
import { TENORS } from 'utils/constants';
import { noop } from 'utils/common';
import useTenorActivation from './useTenorActivation';
import useTenorDeactivation from './useTenorDeactivation';
import TenorControl from './TenorControl';

interface TenorControlListProps {
  inactive?: boolean;
  disabled?: boolean;
}

const TenorControlList: React.FC<TenorControlListProps> = ({ inactive = false, disabled }) => {
  const activeTenors = useActiveTenors();
  const activateTenor = useTenorActivation();
  const deactivateTenor = useTenorDeactivation();

  return (
    <ul className={`grid grid-cols-${TENORS.length} gap-x-4 gap-y-1`}>
      {TENORS.map(tenor => {
        const isTenorActive = tenor in activeTenors;
        const toggleTenor = isTenorActive ? deactivateTenor : activateTenor;
        return (
          <li key={tenor}>
            <TenorControl
              variant={inactive ? 'outline' : 'default'}
              active={inactive || isTenorActive}
              onClick={inactive ? noop : toggleTenor}
              tenor={tenor}
              disabled={disabled}
            />
          </li>
        );
      })}
    </ul>
  );
};

export default React.memo(TenorControlList);
