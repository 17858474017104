import React from 'react';
import ReactDOM from 'react-dom';
import { animated, useTransition } from 'react-spring';
import useDisclosure from 'hooks/useDisclosure';

interface BackdropProps {
  show?: boolean;
  className?: string;
  children?: React.ReactNode;
}

const Backdrop: React.FC<BackdropProps> = ({ show = true, className = '', children }) => {
  const { isOpen, close, open } = useDisclosure();

  // Because it mounts on a React-controlled node, we can't mount this AND the mountpoint at the
  // same time. That's why we use a state with an effect. To "delay" rendering until React builds
  // and renders the tree containing the mountpoint
  React.useEffect(() => {
    if (show) {
      open();
      window.requestAnimationFrame(() => {
        document.body.style.overflow = 'hidden';
      });
    } else {
      close();
      window.requestAnimationFrame(() => {
        document.body.style.overflow = '';
      });
    }
  }, [show]);

  const transitions = useTransition(isOpen, null, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  });

  return ReactDOM.createPortal(
    transitions.map(
      ({ item, key, props: styles }) =>
        item && (
          <animated.div
            key={key}
            style={styles}
            className={`fixed top-0 left-0 bg-darkGray-1000 bg-opacity-85 w-screen h-screen will-change-opacity ${className}`}
          >
            {children}
          </animated.div>
        )
    ),
    document.body
  );
};

export default React.memo(Backdrop);
