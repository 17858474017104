import React from 'react';
import { TenorSerializerListNameEnum } from 'api';
import omit from 'lodash/omit';
import create from 'zustand';
import shallow from 'zustand/shallow';

export type ActiveTenors = Partial<Record<TenorSerializerListNameEnum, { isEditable: boolean }>>;

type ActiveTenorsStoreState = {
  activeTenors: ActiveTenors;
  activeTenorOrder: TenorSerializerListNameEnum[];
  addActiveTenor: (tenor: TenorSerializerListNameEnum) => void;
  removeActiveTenor: (tenor: TenorSerializerListNameEnum) => void;
  setActiveTenors: (tenors: ActiveTenors) => void;
  setActiveTenorEditability: (tenor: TenorSerializerListNameEnum, isEditable: boolean) => void;
};

export const useActiveTenorsStore = create<ActiveTenorsStoreState>(set => ({
  activeTenors: {},
  activeTenorOrder: [],
  addActiveTenor: tenor => {
    set(({ activeTenors, activeTenorOrder }) => ({
      activeTenors: { ...activeTenors, [tenor]: { isEditable: true } },
      activeTenorOrder: [...activeTenorOrder, tenor],
    }));
  },
  removeActiveTenor: tenor => {
    set(({ activeTenors, activeTenorOrder }) => ({
      activeTenors: omit(activeTenors, tenor),
      activeTenorOrder: activeTenorOrder.filter(t => t !== tenor),
    }));
  },
  setActiveTenors: tenors => {
    set({
      activeTenors: tenors,
      activeTenorOrder: Object.keys(tenors) as TenorSerializerListNameEnum[],
    });
  },
  setActiveTenorEditability: (tenor, isEditable) => {
    set(({ activeTenors }) => ({ activeTenors: { ...activeTenors, [tenor]: { isEditable } } }));
  },
}));

export const useActiveTenors = () => useActiveTenorsStore(state => state.activeTenors);

export const useActiveTenorOrder = () => useActiveTenorsStore(state => state.activeTenorOrder);

export const useActiveTenor = (tenor: TenorSerializerListNameEnum) =>
  useActiveTenorsStore(React.useCallback(state => state.activeTenors[tenor], [tenor]));

export const useActiveTenorsUtilities = () =>
  useActiveTenorsStore(
    state => ({
      addActiveTenor: state.addActiveTenor,
      removeActiveTenor: state.removeActiveTenor,
      setActiveTenors: state.setActiveTenors,
      setActiveTenorEditability: state.setActiveTenorEditability,
    }),
    shallow
  );
