/* tslint:disable */
/* eslint-disable */
/**
 * FXSweep API
 * This is the API documentation
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ParentCompany,
    ParentCompanyFromJSON,
    ParentCompanyFromJSONTyped,
    ParentCompanyToJSON,
    TenorSerializerList,
    TenorSerializerListFromJSON,
    TenorSerializerListFromJSONTyped,
    TenorSerializerListToJSON,
} from './';

/**
 * 
 * @export
 * @interface LimitPreview
 */
export interface LimitPreview {
    /**
     * 
     * @type {number}
     * @memberof LimitPreview
     */
    id: number;
    /**
     * 
     * @type {ParentCompany}
     * @memberof LimitPreview
     */
    pairCompany: ParentCompany;
    /**
     * 
     * @type {number}
     * @memberof LimitPreview
     */
    limit: number | null;
    /**
     * 
     * @type {TenorSerializerList}
     * @memberof LimitPreview
     */
    tenor: TenorSerializerList;
}

export function LimitPreviewFromJSON(json: any): LimitPreview {
    return LimitPreviewFromJSONTyped(json, false);
}

export function LimitPreviewFromJSONTyped(json: any, ignoreDiscriminator: boolean): LimitPreview {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'pairCompany': ParentCompanyFromJSON(json['pair_company']),
        'limit': json['limit'],
        'tenor': TenorSerializerListFromJSON(json['tenor']),
    };
}

export function LimitPreviewToJSON(value?: LimitPreview | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'pair_company': ParentCompanyToJSON(value.pairCompany),
        'limit': value.limit,
        'tenor': TenorSerializerListToJSON(value.tenor),
    };
}


