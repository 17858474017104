import React from 'react';
import { ReactComponent as Spinner } from 'assets/icons/spinner.svg';
import withSEO from 'hoc/withSEO';
import OrdersCardList from './OrdersCardList';
import Header from './Header';
import useFetchTenorsWithActiveOrders from './useFetchTenorsWithActiveOrders';
import TradesDrawer from './TradesDrawer';

const Dashboard: React.FC = () => {
  const { isLoading } = useFetchTenorsWithActiveOrders();
  return (
    <div className="min-h-screen flex flex-col">
      <Header />
      <main className="relative flex-grow bg-darkGray-1000 px-8 py-3">
        <div className="container">
          {isLoading ? (
            <div className="flex w-screen h-screen fixed top-0 left-0 justify-center items-center">
              <Spinner className="animate-spin h-8 w-8 text-white" aria-label="Spinner" />
            </div>
          ) : (
            <React.Fragment>
              <OrdersCardList />
              <div className="fixed h-(screen-no-header) top-28 -mt-0.5 right-0 z-10">
                <TradesDrawer />
              </div>
            </React.Fragment>
          )}
        </div>
      </main>
    </div>
  );
};

export default withSEO({ title: 'Dashboard' })(Dashboard);
