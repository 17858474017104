import React from 'react';
import useTimer from 'hooks/useTimer';
import { minTwoDigits } from 'utils/common';

interface TimerProps {
  from: number;
}

const Timer: React.FC<TimerProps> = ({ from }) => {
  const { timeElapsed: timeMs } = useTimer({ backwards: true, from });

  const seconds = Math.floor(timeMs / 1000);
  const minutesDisplay = minTwoDigits(Math.floor(seconds / 60));
  const secondsDisplay = minTwoDigits(seconds % 60);

  return (
    <div className={`w-7 ${seconds < 60 ? 'text-red' : ''}`}>
      {`${minutesDisplay}:${secondsDisplay}`}
    </div>
  );
};

export default React.memo(Timer);
