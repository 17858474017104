import React from 'react';
import { useMutation } from 'react-query';
import { ordersApi, TenorSerializerListNameEnum } from 'api';
import { useSnackbar } from 'hooks/useSnackbar';
import { useActiveTenorsUtilities } from 'hooks/useActiveTenors';
import { CurrenciesEnum } from 'utils/constants';
import { OrdersFormValues } from '../OrdersCard';

export const useSubmitTenorOrders = (tenor: TenorSerializerListNameEnum) => {
  const { setActiveTenorEditability } = useActiveTenorsUtilities();
  const { pushSnackbar } = useSnackbar();

  const mutationFn = (values: OrdersFormValues) =>
    ordersApi.ordersCreate({
      data: {
        tenor,
        fundingCurrency: values.fundingCurrency,
        goodUntil: values.goodUntil,
        details: Object.values(CurrenciesEnum).map(currency => ({
          currency,
          balance: values.balance[currency] === '' ? null : (values.balance[currency] as number),
          limit: values.limit[currency] === '' ? null : (values.limit[currency] as number),
        })),
      },
    });

  const { mutateAsync } = useMutation(mutationFn, {
    onSuccess: () => {
      setActiveTenorEditability(tenor, false);
      pushSnackbar({ message: 'Your orders have been sent!' });
    },
    onError: () => {
      setActiveTenorEditability(tenor, true);
      pushSnackbar({ variant: 'error', message: 'An unknown error has occurred!' });
    },
  });

  return React.useCallback(
    async (values: OrdersFormValues) => {
      try {
        await mutateAsync(values);
      } catch (err) {}
    },
    [mutateAsync]
  );
};

export default useSubmitTenorOrders;
