import React from 'react';
import useRealTimeQuery from 'hooks/useRealTimeQuery';
import { CurrenciesEnum } from 'utils/constants';
import { TenorSerializerListNameEnum } from 'api';

export const getFirebasePathKey = () => `unavailable_on_currencies`;

const useTenorDisabledCurrencies = (tenor: TenorSerializerListNameEnum) => {
  const query = useRealTimeQuery<CurrenciesEnum[]>(getFirebasePathKey(), {
    initialData: [],
  });

  return React.useMemo(
    () => ({
      ...query,
      data: tenor === TenorSerializerListNameEnum.ON ? query.data ?? [] : [],
    }),
    [query, tenor]
  );
};

export default useTenorDisabledCurrencies;
