import React from 'react';
import useTimer from 'hooks/useTimer';
import { minTwoDigits } from 'utils/common';

interface TimerProps {
  from: Date;
  onTick?: (seconds: number) => void;
}

const Timer: React.FC<TimerProps> = ({ from, onTick }) => {
  const now = React.useMemo(() => Date.now(), []);
  const { timeElapsed } = useTimer({ from: now - from.getTime() });

  const seconds = Math.floor(timeElapsed / 1000);
  React.useEffect(() => {
    onTick?.(seconds);
  }, [seconds, onTick]);

  const minutesDisplay = minTwoDigits(Math.floor(seconds / 60));
  const secondsDisplay = minTwoDigits(seconds % 60);

  return `${minutesDisplay}:${secondsDisplay}` as any;
};

export default Timer;
