import React from 'react';
import Popover from 'components/Popover';
import Auth from 'clients/Auth';
import { ReactComponent as User } from 'assets/icons/user.svg';
import { ReactComponent as Logout } from 'assets/icons/logout.svg';

interface UserDropdownProps {}

const UserDropdown: React.FC<UserDropdownProps> = () => {
  return (
    <Popover
      content={
        <div className="bg-darkGray-600 p-2 w-32 text-white text-sm rounded mt-6 -ml-10 shadow-2xl">
          <button
            className="flex items-center space-x-3 p-2 hover:bg-darkGray-800 rounded w-full font-bold"
            onClick={Auth.logout}
          >
            <Logout className="fill-current text-darkGray-100" />
            <span>Log out</span>
          </button>
        </div>
      }
    >
      {({ isOpen, toggle }) => (
        <button aria-label="Toggle User Menu" onClick={toggle}>
          <User className={`fill-current ${isOpen ? 'text-white' : 'text-darkGray-100'}`} />
        </button>
      )}
    </Popover>
  );
};

export default React.memo(UserDropdown);
