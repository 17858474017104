/* tslint:disable */
/* eslint-disable */
/**
 * FXSweep API
 * This is the API documentation
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    TenorSerializerList,
    TenorSerializerListFromJSON,
    TenorSerializerListToJSON,
} from '../models';

/**
 * 
 */
export class TenorsApi extends runtime.BaseAPI {

    /**
     */
    async tenorsListRaw(): Promise<runtime.ApiResponse<Array<TenorSerializerList>>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // api_key authentication
        }

        const response = await this.request({
            path: `/tenors/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(TenorSerializerListFromJSON));
    }

    /**
     */
    async tenorsList(): Promise<Array<TenorSerializerList>> {
        const response = await this.tenorsListRaw();
        return await response.value();
    }

}
