import React from 'react';
import { Helmet } from 'react-helmet-async';

interface WithSEOProps {
  title: string;
}

function withSEO<P>({ title }: WithSEOProps) {
  return (Component: React.FC<P>) => {
    const ComponentWithSEO: React.FC<P> = props => {
      return (
        <React.Fragment>
          <Helmet titleTemplate="%s | FXSweep">
            <title>{title}</title>
          </Helmet>
          <Component {...props} />
        </React.Fragment>
      );
    };
    return ComponentWithSEO;
  };
}

export default withSEO;
