import React from 'react';
import Auth from 'clients/Auth';
import { useQueryClient } from 'react-query';
import { useAuthUtilities } from 'hooks/useAuth';

const Init: React.FC = ({ children = null }) => {
  const queryClient = useQueryClient();
  const { setAuthState } = useAuthUtilities();

  React.useEffect(() => {
    (async () => {
      try {
        const user = await Auth.getCurrentUser();
        setAuthState({ isAuthenticated: true, authUser: user });
      } catch (err) {
        setAuthState({ isAuthenticated: false, authUser: null });
      }
    })();

    const unlisten = Auth.listen((event, user) => {
      if (event === 'login') {
        setAuthState({ isAuthenticated: true, authUser: user });
      } else if (event === 'logout') {
        setAuthState({ isAuthenticated: false, authUser: null });
        queryClient.clear();
      }
    });

    return () => unlisten();
  }, []);

  return children as React.ReactElement;
};

export default Init;
