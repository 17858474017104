import React from 'react';
import { logError } from 'utils/errors';
// import { Card, Text, Flex } from 'pouncejs';

interface ErrorBoundaryProps {
  fallbackStrategy?: 'passthrough' | 'invisible' | 'placeholder';
}

interface ErrorBoundaryState {
  hasError: boolean;
}

class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  static defaultProps: Partial<ErrorBoundaryProps>;

  state = { hasError: false };

  componentDidCatch(error: Error, info: React.ErrorInfo) {
    // Display fallback UI
    this.setState({ hasError: true });

    // send this error to our logging service.
    logError(error, { extras: info });
  }

  render() {
    const { fallbackStrategy, children } = this.props;

    // if no error occurs -> render as normal
    if (!this.state.hasError) {
      return children;
    }

    // else decide what to show based on the selected strategy
    switch (fallbackStrategy) {
      case 'invisible':
        return null;
      case 'placeholder':
        return (
          <div className="bg-red-400 h-full">
            <div className="flex justify-center w-full h-full">
              <p className="py-5 px-4">
                Something went wrong and we could not correctly display this content
              </p>
            </div>
          </div>
        );
      case 'passthrough':
      default:
        return children;
    }
  }
}

ErrorBoundary.defaultProps = {
  fallbackStrategy: 'placeholder',
};

export default ErrorBoundary;
