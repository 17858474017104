import { QueryClient } from 'react-query';
import { isTest } from 'utils/common';

export const createQueryClient = () =>
  new QueryClient({
    defaultOptions: {
      queries: {
        retry: false,
        staleTime: isTest() ? undefined : Infinity,
      },
    },
  });
