/* tslint:disable */
/* eslint-disable */
/**
 * FXSweep API
 * This is the API documentation
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface OrderDetailSerializerInput
 */
export interface OrderDetailSerializerInput {
    /**
     * 
     * @type {string}
     * @memberof OrderDetailSerializerInput
     */
    currency: string;
    /**
     * 
     * @type {number}
     * @memberof OrderDetailSerializerInput
     */
    balance: number | null;
    /**
     * 
     * @type {number}
     * @memberof OrderDetailSerializerInput
     */
    limit: number | null;
}

export function OrderDetailSerializerInputFromJSON(json: any): OrderDetailSerializerInput {
    return OrderDetailSerializerInputFromJSONTyped(json, false);
}

export function OrderDetailSerializerInputFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrderDetailSerializerInput {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'currency': json['currency'],
        'balance': json['balance'],
        'limit': json['limit'],
    };
}

export function OrderDetailSerializerInputToJSON(value?: OrderDetailSerializerInput | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'currency': value.currency,
        'balance': value.balance,
        'limit': value.limit,
    };
}


