import React from 'react';
import { Link } from 'react-router-dom';
import urls from 'urls';
import withSEO from 'hoc/withSEO';

const Page404: React.FC = () => {
  return (
    <div className="flex flex-col h-screen justify-center items-center text-white space-y-5">
      <h1 className="text-5xl font-light">Four oh four</h1>
      <Link to={urls.dashboard()} className="btn btn-primary-light">
        Back to Dashboard
      </Link>
    </div>
  );
};

export default withSEO({ title: 'Not Found' })(Page404);
