import React from 'react';


const useDisclosure = (isInitiallyOpen = false) => {
  const [isOpen, setIsOpen] = React.useState(isInitiallyOpen);

  const open = React.useCallback(() => setIsOpen(true), [setIsOpen]);
  const close = React.useCallback(() => setIsOpen(false), [setIsOpen]);
  const toggle = React.useCallback(() => setIsOpen(open => !open), [setIsOpen]);

  return React.useMemo(
    () => ({
      isOpen,
      open,
      close,
      toggle,
    }),
    [isOpen, open, close, toggle]
  );
};

export default useDisclosure;
