import React from 'react';

function usePrevious<T>(value: T) {
  const previous = React.useRef<T>();

  React.useEffect(() => {
    previous.current = value;
  }, [value]);

  return previous.current;
}

export default usePrevious;
