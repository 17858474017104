import React from 'react';
import SignInForm from './SignInForm';
import { ReactComponent as Logo } from 'assets/icons/logo.svg';
import withSEO from 'hoc/withSEO';

const SignIn: React.FC = () => {
  return (
    <main className="animate-fade-in h-screen w-screen flex flex-col justify-center items-center bg-darkGray-800 space-y-8">
      <h1>
        <Logo aria-label="FXSweep Logo" />
      </h1>
      <SignInForm />
    </main>
  );
};

export default withSEO({ title: 'Login' })(SignIn);
