/* tslint:disable */
/* eslint-disable */
/**
 * FXSweep API
 * This is the API documentation
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    TicketInput,
    TicketInputFromJSON,
    TicketInputToJSON,
    TicketReturn,
    TicketReturnFromJSON,
    TicketReturnToJSON,
} from '../models';

export interface TicketCreateRequest {
    data: TicketInput;
}

/**
 * 
 */
export class TicketApi extends runtime.BaseAPI {

    /**
     */
    async ticketCreateRaw(requestParameters: TicketCreateRequest): Promise<runtime.ApiResponse<TicketReturn>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling ticketCreate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // api_key authentication
        }

        const response = await this.request({
            path: `/ticket/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TicketInputToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => TicketReturnFromJSON(jsonValue));
    }

    /**
     */
    async ticketCreate(requestParameters: TicketCreateRequest): Promise<TicketReturn> {
        const response = await this.ticketCreateRaw(requestParameters);
        return await response.value();
    }

}
