import { Configuration, LimitsApi, OrdersApi, TenorsApi, TicketApi, TokenApi } from './openapi';
import Auth from 'clients/Auth';
import { RealTimeApi } from './real-time';

const withAuthorizationHeader = (init: RequestInit, token?: string) => ({
  ...init,
  headers: {
    ...init.headers,
    Authorization: `Bearer ${token}`,
  },
});

export const protectedApiConfig = new Configuration({
  middleware: [
    {
      pre: async ({ url, init }) => {
        const token = await Auth.getAccessToken();
        return {
          url,
          init: withAuthorizationHeader(init, token),
        };
      },
      post: async ({ response }) => {
        if (response.status === 401) {
          await Auth.logout();
        }

        return response.status >= 400 ? response.json() : response;
      },
    },
  ],
});

export const publicApiConfig = new Configuration({
  middleware: [
    {
      post: async ({ response }) => (response.status >= 400 ? response.json() : response),
    },
  ],
});

export * from './openapi';
export * from './real-time';

export const limitsApi = new LimitsApi(protectedApiConfig);
export const ordersApi = new OrdersApi(protectedApiConfig);
export const ticketApi = new TicketApi(protectedApiConfig);
export const tenorsApi = new TenorsApi(protectedApiConfig);
export const tokenApi = new TokenApi(publicApiConfig);
export const realTimeApi = new RealTimeApi();
