import React from 'react';
import Button from 'components/Button';
import NumberInput from 'components/NumberInput';
import { ReactComponent as Sync } from 'assets/icons/sync.svg';
import { ReactComponent as Check } from 'assets/icons/check.svg';
import { ReactComponent as Cancel } from 'assets/icons/cancel.svg';
import theme from 'theme';
import usePrevious from 'hooks/usePrevious';
import * as Yup from 'yup';
import { Form, Formik, Field } from 'formik';
import { isTest } from 'utils/common';

// Design team has made an undocumented exception for this particular input
const inputStyleOverrides = { backgroundColor: '#111' };

export interface TradeNegotiationFormValues {
  negotiatedAmount: number;
}

export interface TradeNegotiationFormProps {
  initialValues: TradeNegotiationFormValues;
  allowedNumbers: 'positive' | 'negative';
  onAccept: (values: TradeNegotiationFormValues) => Promise<any>;
  onReject: (values: TradeNegotiationFormValues) => void;
  isEditable: boolean;
}

const TradeNegotiationForm: React.FC<TradeNegotiationFormProps> = ({
  initialValues,
  allowedNumbers,
  onAccept,
  onReject,
  isEditable,
}) => {
  const inputWrapperRef = React.useRef<HTMLInputElement>(null);
  const inputIconRef = React.useRef<SVGSVGElement>(null);

  const prevInitialValues = usePrevious(initialValues);
  const wasUpdatedFromCounterparty = prevInitialValues && prevInitialValues !== initialValues;

  const validationSchema = React.useMemo(
    () =>
      Yup.object({
        negotiatedAmount:
          allowedNumbers === 'positive' ? Yup.number().positive() : Yup.number().negative(),
      }),
    [allowedNumbers]
  );

  React.useEffect(() => {
    if (wasUpdatedFromCounterparty && !isTest()) {
      inputWrapperRef.current?.animate(
        [
          { borderColor: 'initial' },
          { borderColor: theme.colors.green },
          { borderColor: 'initial' },
        ],
        { duration: 1500, iterations: 2 }
      );

      inputIconRef.current?.animate([{ opacity: 0 }, { opacity: 1 }, { opacity: 0 }], {
        duration: 1500,
        iterations: 2,
      });
    }
  }, [initialValues]);

  return (
    <Formik<TradeNegotiationFormValues>
      initialValues={initialValues}
      onSubmit={onAccept}
      validationSchema={validationSchema}
      enableReinitialize
    >
      {({ isSubmitting, isValid, values }) => (
        <Form>
          <div className="flex justify-center items-center space-x-3">
            <div ref={inputWrapperRef} className="relative border border-transparent rounded">
              <Field
                as={NumberInput}
                name="negotiatedAmount"
                id="negotiatedAmount"
                colored
                className="w-20 h-6 pl-4 input-live"
                readOnly={!isEditable}
                maxDecimals={2}
                style={inputStyleOverrides}
              />
              <label htmlFor="negotiatedAmount" className="sr-only">
                Negotiated Amount
              </label>
              <Sync ref={inputIconRef} className="absolute inset-y-0 my-auto left-2 opacity-0" />
            </div>
            <Button
              type="submit"
              variantColor="green"
              disabled={!isValid || isSubmitting || !isEditable || !values.negotiatedAmount}
            >
              <Check className="fill-current mr-1.5" />
              Accept
            </Button>
            <Button variantColor="red" disabled={isSubmitting} onClick={() => onReject(values)}>
              <Cancel className="fill-current mr-1.5" />
              Reject
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default React.memo(TradeNegotiationForm);
