import { CurrenciesEnum } from 'utils/constants';
import { TenorSerializerListNameEnum } from 'api';
import useRealTimeQuery from 'hooks/useRealTimeQuery';

export const getFirebasePathKey = (
  tenor: TenorSerializerListNameEnum,
  fundingCurrency: CurrenciesEnum
) => `market_data/tenors/T_${tenor}/${fundingCurrency}`;

export type TenorCurrencyPairMarketData = {
  fwd_pts: number;
  spot: number;
};

export type TenorCurrencyMarketData = {
  [pair: string]: TenorCurrencyPairMarketData;
};

const useTenorCurrencyMarketData = (
  tenor: TenorSerializerListNameEnum,
  fundingCurrency: CurrenciesEnum
) =>
  useRealTimeQuery<TenorCurrencyMarketData>(getFirebasePathKey(tenor, fundingCurrency), {
    keepPreviousData: true,
  });

export default useTenorCurrencyMarketData;
