import Cookies from 'js-cookie';
import { realTimeApi, tokenApi } from 'api';

export const ACCESS_TOKEN_KEY = 'access_token';
export const REFRESH_TOKEN_KEY = 'refresh_token';

export type User = {
  userId: number;
};

export type Event = 'login' | 'logout';
export type Listener = (event: Event, payload: User | null) => void;

class AuthClass {
  private handlers: Listener[];

  constructor() {
    this.handlers = [];

    this.login = this.login.bind(this);
    this.logout = this.logout.bind(this);
  }

  private callAllListeners(event: Event, payload: any) {
    this.handlers.forEach(fn => fn && fn(event, payload));
  }

  private getTokenClaims(token: string) {
    return JSON.parse(atob(token.split('.')[1])) as any;
  }

  private extractUserFromAccessToken(token: string) {
    const claims = this.getTokenClaims(token);
    return { userId: claims.user_id };
  }

  private persistToken(key: string, token: string) {
    const jwtClaims = this.getTokenClaims(token);
    const expires = new Date(((jwtClaims.expires ?? jwtClaims.exp) as number) * 1000);

    Cookies.set(key, token, { expires });
  }

  private clearToken(token: string) {
    Cookies.remove(token);
  }

  public listen(fn: Listener) {
    const that = this;

    that.handlers.push(fn);
    return function () {
      that.handlers = that.handlers.filter(handler => handler !== fn);
    };
  }

  public async getAccessToken() {
    const accessToken = Cookies.get(ACCESS_TOKEN_KEY);
    if (!accessToken) {
      const newTokens = await tokenApi.tokenRefreshCreate({
        data: { refresh: await this.getRefreshToken() },
      });

      this.persistToken(ACCESS_TOKEN_KEY, newTokens.access);
      this.persistToken(REFRESH_TOKEN_KEY, newTokens.refresh);

      return Promise.resolve(newTokens.access);
    }

    return Promise.resolve(accessToken);
  }

  public async getFirebaseToken() {
    const accessToken = await this.getAccessToken();
    return this.getTokenClaims(accessToken).firebase.token as string;
  }

  public async getRefreshToken() {
    const refreshToken = Cookies.get(REFRESH_TOKEN_KEY);
    if (!refreshToken) {
      await this.logout();
      return Promise.reject('Your session has expired');
    }
    return Promise.resolve(refreshToken);
  }

  public async getCurrentUser(): Promise<User> {
    const accessToken = await this.getAccessToken();
    return this.extractUserFromAccessToken(accessToken);
  }

  public login(username: string, password: string): Promise<User> {
    return new Promise(async (resolve, reject) => {
      try {
        const { access, refresh } = await tokenApi.tokenCreate({
          data: { username, password },
        });

        await realTimeApi.connect(this.getTokenClaims(access).firebase.token);

        this.persistToken(ACCESS_TOKEN_KEY, access);
        this.persistToken(REFRESH_TOKEN_KEY, refresh);

        const user = this.extractUserFromAccessToken(access);
        this.callAllListeners('login', user);

        resolve(user);
      } catch (err) {
        reject(err.detail);
      }
    });
  }

  public async logout() {
    await realTimeApi.disconnect();

    this.clearToken(ACCESS_TOKEN_KEY);
    this.clearToken(REFRESH_TOKEN_KEY);

    this.callAllListeners('logout', null);
  }
}

export default AuthClass;
