/* tslint:disable */
/* eslint-disable */
/**
 * FXSweep API
 * This is the API documentation
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    OrderSerializerDelete,
    OrderSerializerDeleteFromJSON,
    OrderSerializerDeleteToJSON,
    OrderSerializerError,
    OrderSerializerErrorFromJSON,
    OrderSerializerErrorToJSON,
    OrderSerializerInput,
    OrderSerializerInputFromJSON,
    OrderSerializerInputToJSON,
} from '../models';

export interface OrdersCreateRequest {
    data: OrderSerializerInput;
}

export interface OrdersDeleteRequest {
    data: OrderSerializerDelete;
}

/**
 * 
 */
export class OrdersApi extends runtime.BaseAPI {

    /**
     */
    async ordersCreateRaw(requestParameters: OrdersCreateRequest): Promise<runtime.ApiResponse<OrderSerializerInput>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling ordersCreate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // api_key authentication
        }

        const response = await this.request({
            path: `/orders/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: OrderSerializerInputToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => OrderSerializerInputFromJSON(jsonValue));
    }

    /**
     */
    async ordersCreate(requestParameters: OrdersCreateRequest): Promise<OrderSerializerInput> {
        const response = await this.ordersCreateRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async ordersDeleteRaw(requestParameters: OrdersDeleteRequest): Promise<runtime.ApiResponse<OrderSerializerError>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling ordersDelete.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // api_key authentication
        }

        const response = await this.request({
            path: `/orders/`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
            body: OrderSerializerDeleteToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => OrderSerializerErrorFromJSON(jsonValue));
    }

    /**
     */
    async ordersDelete(requestParameters: OrdersDeleteRequest): Promise<OrderSerializerError> {
        const response = await this.ordersDeleteRaw(requestParameters);
        return await response.value();
    }

}
