import React from 'react';

const DURATION = 5000;

export interface SnackbarProps {
  message: string;
  variant?: 'default' | 'error';
  destroy: () => void;
}

/**
 * A Snackbar is a special version of Alert that gets shown as a response to a user's action in
 * order to provide feedback about the outcome of his action
 */
const Snackbar: React.FC<SnackbarProps> = ({ destroy, message, variant = 'default' }) => {
  const timeoutRef = React.useRef<NodeJS.Timeout | number>();

  React.useEffect(() => {
    timeoutRef.current = setTimeout(destroy, DURATION);
    return () => clearTimeout(timeoutRef.current as number);
  }, [DURATION]);

  return (
    <div
      className={`py-4 bg-black font-medium text-md rounded-md px-14 ${
        variant === 'error' ? 'text-red' : 'text-white'
      }`}
    >
      {message}
    </div>
  );
};

export default Snackbar;
