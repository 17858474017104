import React from 'react';
import { Route, Switch } from 'react-router-dom';
import GuardedRoute from 'components/utils/GuardedRoute';
import ErrorBoundary from 'components/utils/ErrorBoundary';
import { ReactComponent as Logo } from 'assets/icons/logo.svg';
import urls from 'urls';
import SignInPage from 'pages/SignIn';
import DashboardPage from 'pages/Dashboard';
import Page404 from 'pages/Page404';
import { useAuth } from 'hooks/useAuth';

const Routes: React.FC = () => {
  const { isAuthenticated } = useAuth();

  // until we can decide whether the user is authenticated or not, don't render anything
  if (isAuthenticated === undefined) {
    return (
      <div className="animate-fade-in-delayed opacity-0 flex justify-center items-center w-screen h-screen">
        <Logo aria-label="FXSweep Logo" className="h-60" />
      </div>
    );
  }

  return (
    <ErrorBoundary>
      <Switch>
        <GuardedRoute
          exact
          limitAccessTo="anonymous"
          path={urls.auth.signIn()}
          component={SignInPage}
        />
        <GuardedRoute
          exact
          path={urls.dashboard()}
          limitAccessTo="authenticated"
          component={DashboardPage}
        />
        <Route component={Page404} />
      </Switch>
    </ErrorBoundary>
  );
};

export default React.memo(Routes);
