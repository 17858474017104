import React from 'react';
import { animated, useTransition } from 'react-spring';
import { useActiveTenorOrder } from 'hooks/useActiveTenors';
import OrdersCard from './OrdersCard';

const OrdersCardList: React.FC = () => {
  const activeTenorOrder = useActiveTenorOrder();

  const transitions = useTransition(activeTenorOrder, key => key, {
    from: { opacity: 0, transform: 'translateY(15px)' },
    enter: { opacity: 1, transform: 'translateY(0)' },
    leave: { opacity: 0, transform: 'translateY(15px)', pointerEvents: 'none' },
    trail: 300,
  });

  return (
    <div className="grid grid-cols-3 gap-x-2 gap-y-3">
      {transitions.map(({ item, key, props: styles }) => (
        <animated.div key={key} style={styles}>
          <OrdersCard key={key} tenor={item} />
        </animated.div>
      ))}
      {!transitions.length && (
        <div className="flex w-screen h-screen fixed top-0 left-0 justify-center items-center text-2xl">
          <h1 className="text-white text-opacity-25">No tenors selected</h1>
        </div>
      )}
    </div>
  );
};

export default React.memo(OrdersCardList);
