import React from 'react';
import dayjs from 'dayjs';
import utcPlugin from 'dayjs/plugin/utc';
import cn from 'classnames';
import isDeepEqual from 'fast-deep-equal';
import { TicketInputActionEnum } from 'api';
import { ReactComponent as Info } from 'assets/icons/info.svg';
import { TradeStatusEnum } from 'utils/constants';
import { useModal } from 'hooks/useModal';
import { MODALS } from 'components/utils/ModalManager';
import { getRateDecimalsForCurrencyPair } from 'utils/common';
import { Trade } from '../../useFetchTrades';
import {
  checkTradeActionability,
  tradeStatusToDisplayValue,
  tradeStatusToNegotiationText,
} from '../../utils';
import TradeNegotiationForm, { TradeNegotiationFormValues } from './TradeNegotiationForm';
import useUpdateTrade from './useUpdateTrade';
import Timer from './Timer';

dayjs.extend(utcPlugin);

type TradeAges = 'new' | 'recent' | 'old';

interface PendingTradesTableRowProps {
  trade: Trade;
  isActive: boolean;
  toggleActive: (id: number) => void;
}

const PendingTradesTableRow: React.FC<PendingTradesTableRowProps> = ({
  trade,
  isActive,
  toggleActive,
}) => {
  const { showModal, hideModal } = useModal();
  const [tradeAge, setTradeAge] = React.useState<TradeAges>();
  const { mutateAsync: updateTrade } = useUpdateTrade();
  const initialValues = React.useMemo(() => ({ negotiatedAmount: trade.negotiatedAmount }), [
    trade.negotiatedAmount,
  ]);

  React.useEffect(() => {
    if (isActive && trade.status === TradeStatusEnum.REJECTED_BY_OTHER) {
      showModal({
        modal: MODALS.TRADE_COUNTERPARTY_REJECTION,
        props: {
          trade,
          onAcknowledge: async reEnterOrder => {
            hideModal();

            try {
              await updateTrade({
                negotiatedAmount: trade.negotiatedAmount,
                id: trade.id,
                reEnterOrder,
                action: TicketInputActionEnum.REJECT,
                blockTrader: null,
                rejectionComment: trade.comment!,
              });
            } catch (err) {
              // noop
            }
          },
        },
      });
    }
  }, [isActive, trade.status]);

  const handleTimerTick = React.useCallback(
    (seconds: number) => {
      if (seconds <= 120) {
        setTradeAge('new');
      } else if (seconds <= 300) {
        setTradeAge('recent');
      } else {
        setTradeAge('old');
      }
    },
    [setTradeAge]
  );

  const handleNegotiationAcceptance = React.useCallback(
    async ({ negotiatedAmount }: TradeNegotiationFormValues) => {
      try {
        toggleActive(trade.id);

        await updateTrade({
          negotiatedAmount,
          id: Number(trade.id),
          reEnterOrder: null,
          action: TicketInputActionEnum.ACCEPT,
          blockTrader: null,
          rejectionComment: null,
        });
      } catch (err) {
        // noop
      }
    },
    [updateTrade, trade.id, toggleActive]
  );

  const handleNegotiationRejection = React.useCallback(
    ({ negotiatedAmount }: TradeNegotiationFormValues) => {
      showModal({
        modal: MODALS.TRADE_REJECTION,
        props: {
          trade,
          onComplete: async values => {
            hideModal();
            toggleActive(trade.id);

            try {
              await updateTrade({
                negotiatedAmount,
                id: Number(trade.id),
                reEnterOrder: values.reEnterOrder,
                action: TicketInputActionEnum.REJECT,
                blockTrader: values.blockTrader,
                rejectionComment: values.rejectionComment,
              });
            } catch (err) {
              // noop
            }
          },
        },
      });
    },
    [updateTrade, showModal, hideModal, trade, toggleActive]
  );

  const isTradeActionable = checkTradeActionability(trade);
  const rowClasses = cn({
    'text-white text-opacity-50 text-sm w-full cursor-pointer': true,
    'bg-gradient-to-r from-green to-transparent': isTradeActionable && tradeAge === 'new',
    'bg-gradient-to-r from-orange to-transparent': isTradeActionable && tradeAge === 'recent',
    'bg-gradient-to-r from-bronze to-transparent': isTradeActionable && tradeAge === 'old',
    'bg-gradient-to-r from-garnet to-transparent': trade.status === TradeStatusEnum.REJECTED_BY_OTHER, // prettier-ignore
  });

  return (
    <React.Fragment>
      <tr data-testid={trade.id} className={rowClasses} onClick={() => toggleActive(trade.id)}>
        <td className="font-bold pl-3 p-1 rounded-l-sm text-white text-opacity-100">{trade.id}</td>
        <td className="text-center p-1">{trade.ccyPair}</td>
        <td className="text-center p-1 whitespace-nowrap">
          {dayjs.utc(trade.cycle).format('MM.YY HH:mm')}
        </td>
        <td className="text-center p-1">
          {trade.company === trade.pairCompany ? 'Internal' : 'External'}
        </td>
        <td className="p-1 pl-2 whitespace-nowrap">{trade.pairCompany}</td>
        <td className="text-center p-1">{trade.tenor}</td>
        <td className={`text-center p-1 ${trade.baseAmount < 0 ? 'text-red' : 'text-green'}`}>
          {trade.baseAmount.toFixed(2)}
        </td>
        <td className={`text-center p-1 ${trade.quotedAmount < 0 ? 'text-red' : 'text-green'}`}>
          {trade.quotedAmount.toFixed(2)}
        </td>
        <td className="text-center p-1">
          {trade.startRate.toFixed(getRateDecimalsForCurrencyPair(trade.ccyPair))}
        </td>
        <td className="text-center p-1">{trade.fwdPoints.toFixed(2)}</td>
        <td className="p-1 pl-2 space-x-2 whitespace-nowrap">
          <span className="text-xs border border-white border-opacity-10 rounded w-8 inline-block text-center">
            <Timer from={trade.createdAt} onTick={handleTimerTick} />
          </span>
          <span className="text-white text-opacity-75">
            {tradeStatusToDisplayValue(trade.status)}
          </span>
        </td>
      </tr>
      {isActive && trade.status !== TradeStatusEnum.REJECTED_BY_OTHER && (
        <tr className="bg-darkGray-900 animate-fade-in">
          <td className="py-2 pl-3" colSpan={5}>
            <div className="flex items-center space-x-2 font-medium text-xs text-white text-opacity-50">
              <Info />
              <p className="italic">{tradeStatusToNegotiationText(trade.status)}</p>
            </div>
          </td>
          <td className="py-2 pr-3" colSpan={6}>
            <TradeNegotiationForm
              initialValues={initialValues}
              allowedNumbers={trade.baseAmount >= 0 ? 'positive' : 'negative'}
              onAccept={handleNegotiationAcceptance}
              onReject={handleNegotiationRejection}
              isEditable={
                trade.status === TradeStatusEnum.PENDING ||
                trade.status === TradeStatusEnum.PENDING_BY_YOU
              }
            />
          </td>
        </tr>
      )}
    </React.Fragment>
  );
};

export default React.memo(PendingTradesTableRow, isDeepEqual);
