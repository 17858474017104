import { TenorSerializerListNameEnum } from 'api';

export enum CurrenciesEnum {
  USD = 'USD',
  EUR = 'EUR',
  JPY = 'JPY',
  GBP = 'GBP',
  CHF = 'CHF',
  AUD = 'AUD',
  CAD = 'CAD',
  NZD = 'NZD',
  SEK = 'SEK',
  NOK = 'NOK',
  DKK = 'DKK',
}

export enum TradeStatusEnum {
  'ACCEPTED' = 'accepted',
  'REJECTED_BY_YOU' = 'rejected_by_you',
  'REJECTED_BY_OTHER' = 'rejected_by_other',
  'PENDING' = 'pending',
  'PENDING_BY_YOU' = 'pending_by_you',
  'PENDING_BY_OTHER' = 'pending_by_other',
}

export enum TradeStateEnum {
  ACTIVE = 'active',
  FINALIZED = 'finalized',
}

export const TENORS = Object.values(TenorSerializerListNameEnum);
export const CURRENCIES = Object.values(CurrenciesEnum);
