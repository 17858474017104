import React from 'react';
import useNextTradeCycle from './useNextTradeCycle';
import Timer from './Timer';

const TradeCycleCountdown: React.FC = () => {
  const { data: nextTradeCycle, isLoading, isError } = useNextTradeCycle();

  const msFromNow = React.useMemo(() => {
    const diff = new Date(nextTradeCycle ?? 0).getTime() - Date.now();
    return diff > 0 ? diff : 0;
  }, [nextTradeCycle]);

  if (isLoading || isError) {
    return null;
  }

  return <Timer from={msFromNow} />;
};

export default React.memo(TradeCycleCountdown);
