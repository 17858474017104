/* tslint:disable */
/* eslint-disable */
/**
 * FXSweep API
 * This is the API documentation
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    LimitSerializerInput,
    LimitSerializerInputFromJSON,
    LimitSerializerInputFromJSONTyped,
    LimitSerializerInputToJSON,
} from './';

/**
 * 
 * @export
 * @interface LimitSerializerInputSwagger
 */
export interface LimitSerializerInputSwagger {
    /**
     * 
     * @type {Array<LimitSerializerInput>}
     * @memberof LimitSerializerInputSwagger
     */
    limits: Array<LimitSerializerInput>;
}

export function LimitSerializerInputSwaggerFromJSON(json: any): LimitSerializerInputSwagger {
    return LimitSerializerInputSwaggerFromJSONTyped(json, false);
}

export function LimitSerializerInputSwaggerFromJSONTyped(json: any, ignoreDiscriminator: boolean): LimitSerializerInputSwagger {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'limits': ((json['limits'] as Array<any>).map(LimitSerializerInputFromJSON)),
    };
}

export function LimitSerializerInputSwaggerToJSON(value?: LimitSerializerInputSwagger | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'limits': ((value.limits as Array<any>).map(LimitSerializerInputToJSON)),
    };
}


