/* tslint:disable */
/* eslint-disable */
/**
 * FXSweep API
 * This is the API documentation
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface TenorSerializerList
 */
export interface TenorSerializerList {
  /**
   *
   * @type {string}
   * @memberof TenorSerializerList
   */
  name?: TenorSerializerListNameEnum;
}

export function TenorSerializerListFromJSON(json: any): TenorSerializerList {
  return TenorSerializerListFromJSONTyped(json, false);
}

export function TenorSerializerListFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): TenorSerializerList {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    name: !exists(json, 'name') ? undefined : json['name'],
  };
}

export function TenorSerializerListToJSON(value?: TenorSerializerList | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    name: value.name,
  };
}

/**
 * @export
 * @enum {string}
 */
export enum TenorSerializerListNameEnum {
  ON = 'ON',
  TN = 'TN',
  SN = 'SN',
  _1W = '1W',
  _2W = '2W',
  _3W = '3W',
  _1M = '1M',
  _2M = '2M',
  _3M = '3M',
}
