import React from 'react';
import ReactDOM from 'react-dom';
import { animated, useTransition } from 'react-spring';
import useMeasure from 'react-use-measure';
import Snackbar from 'components/Snackbar';
import { SnackbarItemState, useSnackbar, useSnackbarState } from 'hooks/useSnackbar';

/**
 * A component that acts both as a state-manager and provider. It provides access to methods for
 * managing snackbar instances
 */
export const SnackbarManager: React.FC = () => {
  const snackbars = useSnackbarState();
  const { removeSnackbar } = useSnackbar();

  const [ref, { height }] = useMeasure({ debounce: 100, scroll: false });

  const transitions = useTransition<SnackbarItemState, any>(
    snackbars,
    snackbars.map(({ id }) => id),
    {
      from: { opacity: 0, height: 0 },
      enter: { opacity: 1, height },
      leave: { opacity: 0, height: 0, pointerEvents: 'none' },
      update: { height },
    }
  );

  return ReactDOM.createPortal(
    <div className="fixed bottom-0 left-0 right-0 flex flex-col items-center justify-center z-50 transform translate-y-1">
      {transitions.map(({ item: { id, ...snackbarPublicProps }, key, props: styles }) => (
        <animated.div key={key} style={styles}>
          <div className="pt-3" ref={ref}>
            <Snackbar destroy={() => removeSnackbar(id)} {...snackbarPublicProps} />
          </div>
        </animated.div>
      ))}
    </div>,
    document.body
  );
};

export default React.memo(SnackbarManager) as React.FC;
