import React from 'react';
import * as Yup from 'yup';
import { Field, Form, Formik } from 'formik';
import Button from 'components/Button';
import Auth from 'clients/Auth';
import Input from 'components/Input';

interface SignInFormError {
  _form?: string;
}

interface SignInFormValues extends SignInFormError {
  username: string;
  password: string;
}

const initialValues = {
  username: '',
  password: '',
};

const validationSchema: Yup.SchemaOf<SignInFormValues> = Yup.object().shape({
  username: Yup.string().required(''),
  password: Yup.string().required(''),
  _form: Yup.string(),
});

const SignInForm: React.FC = () => {
  return (
    <Formik<SignInFormValues>
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={async ({ username, password }, { setErrors }) => {
        try {
          return await Auth.login(username, password);
        } catch (reason) {
          setErrors({ _form: reason });
        }
      }}
    >
      {({ isValid, dirty, isSubmitting, errors }) => (
        <Form>
          <div className="space-y-4 text-sm">
            <fieldset className="w-72">
              <label htmlFor="username" className="sr-only">
                Username
              </label>
              <Field
                as={Input}
                className="p-1.5 w-full"
                id="username"
                name="username"
                placeholder="Username"
                aria-required
              />
            </fieldset>
            <fieldset className="w-72">
              <label htmlFor="password" className="sr-only">
                Password
              </label>
              <Field
                as={Input}
                className="p-1.5 w-full"
                id="password"
                name="password"
                type="password"
                placeholder="Password"
                autoComplete="on"
                aria-required
              />
            </fieldset>
            {errors?._form && <p className="text-red text-center mt-3">{errors._form}</p>}
            <div className="flex justify-center pt-4">
              <Button
                type="submit"
                variantColor="white"
                className="w-36"
                loading={isSubmitting}
                disabled={!dirty || !isValid || isSubmitting}
              >
                Login
              </Button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default SignInForm;
