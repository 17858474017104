import useRealTimeQuery from 'hooks/useRealTimeQuery';
import { getUserDataFirebaseKey } from 'hooks/useAuth';
import { TenorSerializerListNameEnum } from 'api';
import { TradeStatusEnum, TradeStateEnum } from 'utils/constants';
import sortBy from 'lodash/sortBy';

export interface FirebaseTrade {
  ticket_id: number;
  cycle: string;
  created_at: string;
  updated_at: string;
  company: string;
  pair_company: string;
  comment?: string;
  ccy_pair: string;
  tenor: TenorSerializerListNameEnum;
  base_amount: number;
  quoted_amount: number;
  negotiated_amount: number;
  start_rate: number;
  fwd_points: number;
  status: TradeStatusEnum;
  state: TradeStateEnum;
}

export type FirebaseTradesRecord = Record<string, FirebaseTrade>;

export interface Trade {
  id: number;
  cycle: Date;
  createdAt: Date;
  updatedAt: Date;
  company: FirebaseTrade['company'];
  pairCompany: FirebaseTrade['pair_company'];
  comment?: FirebaseTrade['comment'];
  ccyPair: FirebaseTrade['ccy_pair'];
  tenor: FirebaseTrade['tenor'];
  baseAmount: FirebaseTrade['base_amount'];
  quotedAmount: FirebaseTrade['quoted_amount'];
  negotiatedAmount: FirebaseTrade['negotiated_amount'];
  startRate: FirebaseTrade['start_rate'];
  fwdPoints: FirebaseTrade['fwd_points'];
  status: FirebaseTrade['status'];
  state: FirebaseTrade['state'];
}

export const useTradesFirebasePathKey = (type: 'live' | 'blotter') => {
  return getUserDataFirebaseKey() + `tickets/${type}`;
};

const useFetchTrades = (type: 'live' | 'blotter') => {
  const firebasePathKey = useTradesFirebasePathKey(type);

  return useRealTimeQuery<FirebaseTradesRecord | null, Trade[]>(firebasePathKey, {
    snapshotTransform: tradesRecord => {
      if (!tradesRecord) {
        return [];
      }

      const trades = Object.values(tradesRecord).map(tradeData => ({
        id: tradeData.ticket_id,
        cycle: new Date(tradeData.cycle),
        createdAt: new Date(tradeData.created_at),
        updatedAt: new Date(tradeData.updated_at ?? tradeData.created_at),
        company: tradeData.company,
        pairCompany: tradeData.pair_company,
        comment: tradeData.comment,
        ccyPair: tradeData.ccy_pair,
        tenor: tradeData.tenor,
        baseAmount: tradeData.base_amount,
        quotedAmount: tradeData.quoted_amount,
        negotiatedAmount: tradeData.negotiated_amount,
        startRate: tradeData.start_rate,
        fwdPoints: tradeData.fwd_points,
        status: tradeData.status,
        state: tradeData.state,
      }));

      // sort the trades from newest to oldest. We sort acctive items by creation_time and when they
      // get finalized, we sort them based on when were they last updated
      const sortingDatetimeField = type === 'live' ? 'createdAt' : 'updatedAt';
      return sortBy(trades, trade => trade[sortingDatetimeField].getTime()).reverse();
    },
  });
};

export default useFetchTrades;
