import React from 'react';
import { CurrenciesEnum } from 'utils/constants';
import find from 'lodash/find';
import findKey from 'lodash/findKey';
import { Field, useFormikContext } from 'formik';
import { TenorSerializerListNameEnum } from 'api';
import { MODALS } from 'components/utils/ModalManager';
import NumberInput from 'components/NumberInput';
import { useModal } from 'hooks/useModal';
import { getRateDecimalsForCurrencyPair } from 'utils/common';
import {
  getCurrencyButtonClasses,
  getFundingCurrencyNegativeSum,
  getFundingCurrencyClasses,
  getInputClasses,
  getRowClasses,
} from './utils';
import useTenorCurrencyMarketData from './useTenorCurrencyMarketData';
import UnicodeDash from './UnicodeDash';
import { OrdersFormValues } from '../OrdersCard';
import useTenorDisabledCurrencies from './useTenorDisabledCurrencies';

interface OrdersTableProps {
  tenor: TenorSerializerListNameEnum;
  readOnly?: boolean;
}

const OrdersTable: React.FC<OrdersTableProps> = ({ tenor, readOnly = false }) => {
  const { showModal, hideModal } = useModal();
  const { values, setFieldValue } = useFormikContext<OrdersFormValues>();

  const { fundingCurrency } = values;
  const { data: marketDataForFundingCurrency } = useTenorCurrencyMarketData(tenor, fundingCurrency);
  const { data: marketDataForUSD } = useTenorCurrencyMarketData(tenor, CurrenciesEnum.USD);
  const { data: disabledCurrencies } = useTenorDisabledCurrencies(tenor);

  const handleFundingCurrencyChange = React.useCallback(
    (fundingCurrency: CurrenciesEnum) => {
      showModal({
        modal: MODALS.FUNDING_CURRENCY_CONFIRMATION,
        props: {
          fundingCurrency,
          onCancel: hideModal,
          onConfirm: () => {
            setFieldValue('fundingCurrency', fundingCurrency);
            hideModal();
          },
        },
      });
    },
    [setFieldValue]
  );

  // Whenever we change the funding currency we "clean" the balance field for the selected FC
  React.useEffect(() => {
    setFieldValue(`balance.${fundingCurrency}`, '');
  }, [fundingCurrency, setFieldValue]);

  return (
    <table className={`table-fixed w-full text-center ${readOnly ? 'pointer-events-none' : ''}`}>
      <colgroup>
        <col />
        <col />
        <col />
        <col className="mt-1 border-l border-r border-solid border-darkGray-100 border-opacity-10" />
        <col />
        <col />
        <col />
      </colgroup>
      <thead>
        <tr className="text-xs text-white text-opacity-30 bg-darkGray-800">
          <th className="pt-1 pb-2 font-normal">Ccy</th>
          <th className="pt-1 pb-2 font-normal">Balance</th>
          <th className="pt-1 pb-2 font-normal">Indic Pts</th>
          <th className="pt-1 pb-2 font-normal">Limit</th>
          <th className="pt-1 pb-2 font-normal">Cross</th>
          <th className="pt-1 pb-2 font-normal">Spot</th>
          <th className="pt-1 pb-2 font-normal">Fwd Pts</th>
        </tr>
      </thead>
      <tbody className="text-sm text-white">
        {Object.values(CurrenciesEnum).map(currency => {
          const isUSD = currency === CurrenciesEnum.USD;
          const isFundingCurrency = currency === fundingCurrency;
          const isCurrencyDisabled = disabledCurrencies!.includes(currency);
          const currencyBalance = values.balance[currency];
          const currencyLimit = values.limit[currency];
          const isLimitSetToMarket = !!currencyBalance && currencyLimit === '';

          const crossUSDData = find(marketDataForUSD, (data, pair) => pair.includes(currency));
          const crossCurrencyPair = findKey(marketDataForFundingCurrency, (data, pair) => pair.includes(currency))!; // prettier-ignore
          const crossCurrencyData = marketDataForFundingCurrency
            ? marketDataForFundingCurrency[crossCurrencyPair!]
            : null;
          const spotRateDecimals = getRateDecimalsForCurrencyPair(crossCurrencyPair);

          const fundingCurrencyNegativeSum = marketDataForUSD
            ? getFundingCurrencyNegativeSum(fundingCurrency, values.balance, marketDataForUSD)
            : null;

          const isRowActive = currencyBalance !== '' || currencyLimit !== '' || isFundingCurrency;
          return (
            <tr key={currency} className={getRowClasses(isRowActive)}>
              <td>
                <button
                  type="button"
                  disabled={isCurrencyDisabled}
                  className={getCurrencyButtonClasses(isFundingCurrency)}
                  onClick={() => !isFundingCurrency && handleFundingCurrencyChange(currency)}
                  aria-label={`Change funding currency to ${currency}`}
                  tabIndex={isFundingCurrency ? -1 : undefined}
                >
                  {currency}
                </button>
              </td>
              <td>
                {isFundingCurrency ? (
                  <span className={getFundingCurrencyClasses(fundingCurrencyNegativeSum)}>
                    {fundingCurrencyNegativeSum ? fundingCurrencyNegativeSum.toFixed(2) : null}
                  </span>
                ) : (
                  <fieldset>
                    <label className="sr-only" htmlFor={`${tenor}-${currency}-balance`}>
                      Balance for {currency}
                    </label>
                    <Field
                      as={NumberInput}
                      id={`${tenor}-${currency}-balance`}
                      name={`balance.${currency}`}
                      readOnly={readOnly || isCurrencyDisabled}
                      className={getInputClasses(currencyBalance, readOnly || isCurrencyDisabled)}
                      placeholder="＋"
                      colored
                      maxDecimals={2}
                      errorAfterTouched
                    />
                  </fieldset>
                )}
              </td>
              <td>{isUSD ? <UnicodeDash /> : crossUSDData?.fwd_pts?.toFixed(2)}</td>
              <td>
                {isUSD ? (
                  <UnicodeDash />
                ) : (
                  <fieldset>
                    <label className="sr-only" htmlFor={`${tenor}-${currency}-limit`}>
                      Limit for {currency}
                    </label>
                    <Field
                      as={NumberInput}
                      id={`${tenor}-${currency}-limit`}
                      name={`limit.${currency}`}
                      readOnly={readOnly}
                      className={isLimitSetToMarket ? 'w-14 h-6 text-2xs' : getInputClasses(currencyLimit, readOnly)} // prettier-ignore
                      placeholder={isLimitSetToMarket ? 'Market' : '＋'}
                      colored
                      maxDecimals={2}
                      errorAfterTouched
                    />
                  </fieldset>
                )}
              </td>
              <td>
                {isFundingCurrency ? (
                  <UnicodeDash />
                ) : (
                  <span className="font-bold">{crossCurrencyPair}</span>
                )}
              </td>
              <td>
                {isFundingCurrency
                  ? (1).toFixed(spotRateDecimals)
                  : crossCurrencyData?.spot?.toFixed(spotRateDecimals)}
              </td>
              <td>{isFundingCurrency ? '0.00' : crossCurrencyData?.fwd_pts?.toFixed(2)}</td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default OrdersTable;
