import React from 'react';
import { Trade } from 'pages/Dashboard/TradesDrawer';
import Button from '../Button';

export interface TradeCounterpartyRejectionModalProps {
  trade: Trade;
  onAcknowledge: (reEnterOrder: boolean) => void;
}

const TradeCounterpartyRejectionModal: React.FC<TradeCounterpartyRejectionModalProps> = ({
  trade,
  onAcknowledge,
}) => {
  return (
    <article className="rounded-md shadow-2xl text-white text-sm overflow-hidden">
      <header className="bg-garnet text-center py-2.5 px-24">
        <h1 className="font-bold">{trade.id}: Rejected</h1>
      </header>
      <section className="px-7 py-5 bg-darkGray-900 space-y-5">
        <table className="w-96 font-medium p-1 border-separate border border-white rounded border-opacity-20">
          <thead>
            <tr className="text-xs text-white text-opacity-30">
              <th className="font-medium py-0.5 px-1">Ccy Pair</th>
              <th className="font-medium py-0.5 px-1">Tenor</th>
              <th className="font-medium py-0.5 px-1">Base Amount</th>
              <th className="font-medium py-0.5 px-1">Quoted Amount</th>
              <th className="font-medium py-0.5 px-1">Points</th>
            </tr>
          </thead>
          <tbody>
            <tr className="text-white text-opacity-70">
              <td className="text-center py-0.5 px-1">{trade.ccyPair}</td>
              <td className="text-center py-0.5 px-1">{trade.tenor}</td>
              <td
                className={`text-center py-0.5 px-1 ${
                  trade.baseAmount < 0 ? 'text-red' : 'text-green'
                }`}
              >
                {trade.baseAmount.toFixed(2)}
              </td>
              <td
                className={`text-center py-0.5 px-1 ${
                  trade.quotedAmount < 0 ? 'text-red' : 'text-green'
                }`}
              >
                {trade.quotedAmount.toFixed(2)}
              </td>
              <td className="text-center py-0.5 px-1">{trade.fwdPoints.toFixed(2)}</td>
            </tr>
          </tbody>
        </table>
        <p className="text-center">
          <b>{trade.pairCompany}</b> has rejected the trade <b>{trade.id}</b> <br />
          for the following reasons:
        </p>
        <footer className="flex justify-center">
          <p className="max-w-sm px-4 py-1.5 italic rounded bg-black bg-opacity-30 text-white text-opacity-70 text-center">
            {trade.comment}
          </p>
        </footer>
      </section>
      <section className="p-4 bg-darkGray-800">
        <p className="w-72 mx-auto text-center my-2 font-bold">
          Would you like to re-enter this order into the next cycle?
        </p>
        <footer className="flex w-full justify-center space-x-2 mt-6">
          <Button onClick={() => onAcknowledge(true)}>Yes</Button>
          <Button variantColor="black" onClick={() => onAcknowledge(false)}>
            No
          </Button>
        </footer>
      </section>
    </article>
  );
};

export default TradeCounterpartyRejectionModal;
