import useRealTimeQuery from 'hooks/useRealTimeQuery';
import { getUserDataFirebaseKey } from 'hooks/useAuth';
import { CurrenciesEnum } from 'utils/constants';
import { OrderSerializerInput, TenorSerializerListNameEnum } from 'api';

export interface FirebaseTenorOrders {
  funding_currency: CurrenciesEnum;
  trade_date: string;
  good_until_date: string | null;
  details: Record<
    string,
    {
      currency: CurrenciesEnum;
      balance: number | null;
      limit: number | null;
    }
  >;
}

const useFetchTenorOrders = (tenor: TenorSerializerListNameEnum) => {
  const firebasePathKey = getUserDataFirebaseKey() + `orders/T_${tenor}`;
  return useRealTimeQuery<FirebaseTenorOrders | null, OrderSerializerInput | null>(
    firebasePathKey,
    {
      snapshotTransform: tenorOrders => {
        if (!tenorOrders) {
          return tenorOrders;
        }

        return {
          tenor,
          goodUntil: tenorOrders.good_until_date ? new Date(tenorOrders.good_until_date) : null,
          fundingCurrency: tenorOrders.funding_currency,
          details: Object.values(tenorOrders.details),
        };
      },
    }
  );
};

export default useFetchTenorOrders;
