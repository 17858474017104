import isDeepEqual from 'fast-deep-equal';
import { CurrenciesEnum } from './constants';

export const minTwoDigits = (n: number) => (n < 10 ? '0' : '') + n;

export const noop = () => {};

export const isTest = () => process.env.NODE_ENV === 'test';

export const getRateDecimalsForCurrencyPair = (pair: string) => {
  return pair?.includes(CurrenciesEnum.JPY) ? 3 : 4;
};

export const isEqualAt = <T extends Record<string, any>>(a: T, b: T, keys: (keyof T)[]) => {
  return keys.every(key => isDeepEqual(a[key], b[key]));
};
