import React from 'react';
import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import isDeepEqual from 'fast-deep-equal';
import { realTimeApi } from 'api';

function identity<T>(x: T) {
  return x;
}

interface UseRealTimeQueryProps<Original, Transformed>
  extends Omit<UseQueryOptions<Transformed>, 'isDataEqual'> {
  snapshotTransform?: (val: Original) => Transformed;
  isDataEqual?: (existingData: Transformed | undefined, transformedData: Transformed) => boolean;
}

function useRealTimeQuery<Original, Transformed = Original>(
  firebasePathKey: string,
  {
    snapshotTransform = identity as any,
    isDataEqual = isDeepEqual,
    ...useQueryOptions
  }: UseRealTimeQueryProps<Original, Transformed> = {}
) {
  const queryClient = useQueryClient();

  React.useEffect(() => {
    const unsubscribe = realTimeApi.subscribe<Original>({
      path: firebasePathKey,
      callback: val => {
        const newData = snapshotTransform(val);
        const oldData = queryClient.getQueryData<Transformed>(firebasePathKey);

        if (!isDataEqual(oldData, newData)) {
          queryClient.setQueryData(firebasePathKey, newData);
        }
      },
    });

    return () => unsubscribe();
  }, [queryClient, firebasePathKey]);

  return useQuery<Transformed, Error>(
    firebasePathKey,
    () => new Promise<Transformed>(() => {}),
    useQueryOptions
  );
}

export default useRealTimeQuery;
