import React from 'react';
import { MODALS } from 'components/utils/ModalManager';
import { TenorSerializerListNameEnum } from 'api';
import { useModal } from 'hooks/useModal';
import { useSnackbar } from 'hooks/useSnackbar';
import useAlgoRunningCheck from 'hooks/useAlgoRunningCheck';
import useCancelTenorOrders from 'hooks/useCancelTenorOrders';
import { useActiveTenors, useActiveTenorsUtilities } from 'hooks/useActiveTenors';

const useTenorDeactivation = () => {
  const state = useActiveTenors();
  const { pushSnackbar } = useSnackbar();
  const { removeActiveTenor } = useActiveTenorsUtilities();
  const { showModal, hideModal } = useModal();
  const { data: isAlgoRunning } = useAlgoRunningCheck();
  const { mutate: cancelTenorOrders } = useCancelTenorOrders();

  return React.useCallback(
    (tenor: TenorSerializerListNameEnum) => {
      const isTenorEditable = state[tenor]?.isEditable;
      if (isTenorEditable) {
        return removeActiveTenor(tenor);
      }

      if (isAlgoRunning) {
        return showModal({
          modal: MODALS.ALGO_IS_RUNNING,
          props: { onConfirm: hideModal },
        });
      }

      return showModal({
        modal: MODALS.TENOR_DEACTIVATION,
        props: {
          onCancel: hideModal,
          onConfirm: () => {
            try {
              removeActiveTenor(tenor);
              hideModal();
              cancelTenorOrders({ tenor });
            } catch (err) {
              pushSnackbar({ variant: 'error', message: 'Failed to cancel your orders' });
            }
          },
        },
      });
    },
    [state, removeActiveTenor, isAlgoRunning, cancelTenorOrders]
  );
};

export default useTenorDeactivation;
