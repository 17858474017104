import { useMutation, useQueryClient } from 'react-query';
import { limitsApi, LimitSerializerInput } from 'api';
import { useSnackbar } from 'hooks/useSnackbar';
import { queryKey } from '../useTenorLimits';

const mutationFn = (limits: LimitSerializerInput[]) => limitsApi.limitsCreate({ data: { limits } });

export const useTenorLimitsUpdate = () => {
  const client = useQueryClient();
  const { pushSnackbar } = useSnackbar();

  return useMutation(mutationFn, {
    onSuccess: data => {
      client.setQueryData(queryKey, data);
      pushSnackbar({ message: 'Limits have been updated!' });
    },
    onError: () => {
      pushSnackbar({ variant: 'error', message: 'An unknown error has occurred!' });
    },
  });
};

export default useTenorLimitsUpdate;
