/* tslint:disable */
/* eslint-disable */
/**
 * FXSweep API
 * This is the API documentation
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface TicketInput
 */
export interface TicketInput {
  /**
   *
   * @type {number}
   * @memberof TicketInput
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof TicketInput
   */
  action: TicketInputActionEnum;
  /**
   *
   * @type {number}
   * @memberof TicketInput
   */
  negotiatedAmount: number;
  /**
   *
   * @type {boolean}
   * @memberof TicketInput
   */
  reEnterOrder: boolean | null;
  /**
   *
   * @type {boolean}
   * @memberof TicketInput
   */
  blockTrader: boolean | null;
  /**
   *
   * @type {string}
   * @memberof TicketInput
   */
  rejectionComment: string | null;
}

export function TicketInputFromJSON(json: any): TicketInput {
  return TicketInputFromJSONTyped(json, false);
}

export function TicketInputFromJSONTyped(json: any, ignoreDiscriminator: boolean): TicketInput {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json['id'],
    action: json['action'],
    negotiatedAmount: json['negotiated_amount'],
    reEnterOrder: json['re_enter_order'],
    blockTrader: json['block_trader'],
    rejectionComment: json['rejection_comment'],
  };
}

export function TicketInputToJSON(value?: TicketInput | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    action: value.action,
    negotiated_amount: value.negotiatedAmount,
    re_enter_order: value.reEnterOrder,
    block_trader: value.blockTrader,
    rejection_comment: value.rejectionComment,
  };
}

/**
 * @export
 * @enum {string}
 */
export enum TicketInputActionEnum {
  ACCEPT = 'ACCEPT',
  REJECT = 'REJECT',
}
