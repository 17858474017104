import React from 'react';
import cn from 'classnames';
import { useField } from 'formik';
import Input, { InputProps } from 'components/Input';

// We maintain both string & number since user enters string & firebase feeds input with numberrs
const countDecimals = (value: string) => {
  return value.split('.')[1]?.length ?? 0;
};

const trimDecimals = (value: string, decimals: number) => {
  const [integerPart, decimalPart] = value.split('.');
  return `${integerPart}.${decimalPart.slice(0, decimals)}`;
};

export interface NumberInputProps extends InputProps {
  colored?: boolean;
  maxDecimals?: number;
}

// The reason we opt for `type="text"` is the ability to limit characters to what the business wants
// (i.e. see the regex below). Normally we'd be fine with a simple `type="number"` input, but no
// matter how much we've tried, we couldn't make it work
const NumberInput: React.FC<NumberInputProps> = ({
  className,
  colored = false,
  maxDecimals,
  onChange,
  ...rest
}) => {
  const [field, , { setValue }] = useField(rest.name!);

  const handleChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;
      if (/^-?[0-9]*\.?[0-9]*$/.test(value)) {
        onChange?.(event);
      }
    },
    [onChange]
  );

  const strValue = String(field.value);
  const numValue = Number(field.value);

  React.useEffect(() => {
    if (typeof maxDecimals === 'number' && countDecimals(strValue) > maxDecimals) {
      setValue(trimDecimals(strValue, maxDecimals));
    }
  }, [strValue, setValue]);

  const classes = cn({
    [className as string]: !!className,
    'input-number-negative': colored && numValue < 0,
    'input-number-positive': colored && numValue > 0,
  });

  return <Input className={classes} onChange={handleChange} {...rest} />;
};

export default NumberInput;
